import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Font,PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import SarabunRegular from '../../Sarabun/Sarabun-Regular.ttf';
import SarabunBold from '../../Sarabun/Sarabun-Bold.ttf';
import PrintReceiptDocument from './PrintDocs';
import PrintTaxInvoice from './PrintTaxInvoice';
import PrintSmallReceipt from './PrintSmallReceipt';

  const PrintReceipt = () => {
    const {BillingType, BillReference, VatInclude} = useParams();
    const [QrCode, setQrCode] = useState('');
    const [BillData, setBillData] = useState({
      "Status": true,
      "bill_id": "",
      "bill_reference": "",
      "bill_address": "",
      "bill_contact": "",
      "bill_name": "",
      "bill_payment": "",
      "bill_time": "",
      "bill_date": "",
      "bill_buyer_taxid": "",
      "st_id": null,
      "st_bill_ref": "",
      "st_status": "",
      "st_time": "",
      "st_date": "",
      "customer_id": null,
      "customer_name": "",
      "customer_bill_number": "",
      "customer_address": "",
      "customer_bill_file": "",
      "customer_balance": "",
      "customer_amount": "",
      "customer_phone": "",
      "customer_bill_reference": "",
      "customer_bill_date": "",
      "customer_bill_timestamp": "",
      "customer_note": null,
      "customer_payment_method": "",
      "bill_dis_amount": 0,
      "bill_dis_code": "",
      "bill_dis_id": 0,
      "bill_dis_ref": ""
    });
    const [NowPrice, setNowPrice] = useState({
        "TotalPrice": 0.00,
        "IncludedDiscount": 0.00,
        "Discount": 0.00
    });
    const [AllItemBag, setAllItemBag] = useState([]);
    const [Admin, setAdmin] = useState({});

    useEffect(() => {
      showHistory();
      showItemBag();
      RequestAdmin();
    }, [])
    

    const showHistory = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillCheckExist/${BillReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
            if(res.data.Status == true){
                setBillData(res.data);
            } 
            console.log(res.data);
            // else{
            //   window.location = '/sPosHome';
            // }
        console.log(res);
      } catch (err) {
        console.error('Error fetching bill history', err);
        // window.location = '/sPosHome';
      }
    };

    const showItemBag = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillItemBag/${BillReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
          if(res.data.InBagDetail.Status == true){
              setAllItemBag(res.data.InBagDetail.InBag);
              setNowPrice(res.data.Price);
              console.log(AllItemBag);
              QRCode(res.data.Price);
          } 
          console.log(res);
      } catch (err) {
        console.error('Error fetching bill history', err);
      }
    };

    const RequestAdmin = () => {
      const token = localStorage.getItem('token');
      if(token != null){
        axios({
          url: `${process.env.REACT_APP_API_SERVER}/authen`,
          method: "POST",
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer "+ token
          },
        })
        .then((res) => {
            // console.log(res.data.decoded);
            setAdmin(res.data.decoded);
        })
        .catch((err) => {
          console.error('Error checking session', err);
        })
      }
    }

    // const QRCode = async (NowPrice) => {
    //   if (Object.entries(NowPrice).length > 0) {
    //     let TotalPrice = 0.00;
    //     var url = `${process.env.REACT_APP_API_SERVER}/PromptPayGenerate/`;
    //     if (NowPrice.Discount > 0) {
    //       if(BillingType == 'Waybill'){
    //         TotalPrice = parseFloat(NowPrice.IncludedDiscount);
    //       } else if(BillingType == 'TaxInvoice'){
    //         TotalPrice = '0';
    //       } else if(BillingType == 'WaybillAndVatIncluded'){
    //         TotalPrice = parseFloat(NowPrice.IncludedDiscount)+parseFloat(NowPrice.IncludedDiscount*0.07);
    //       } else{
    //         TotalPrice = parseFloat(NowPrice.IncludedDiscount);
    //       }
    //     } else {
    //       if(BillingType == 'Waybill'){
    //         TotalPrice = parseFloat(NowPrice.TotalPrice);
    //       } else if(BillingType == 'TaxInvoice'){
    //         TotalPrice = '0';
    //       } else if(BillingType == 'WaybillAndVatIncluded'){
    //         TotalPrice = parseFloat((NowPrice.TotalPrice*1.07).toFixed(2));
    //       } else{
    //         TotalPrice = parseFloat(NowPrice.TotalPrice);
    //       }
          
    //     }
      
    //     try {
    //       url =  `${url}${TotalPrice}`;
    //       const response = await axios.get(url, { responseType: 'blob' });
    //       if(response.status == 200){
    //         const imageUrl = URL.createObjectURL(response.data);
    //         setQrCode(imageUrl);
    //       }
    //     } catch (err) {
    //       // setError(err);
    //       console.error('Error fetching the QR code:', err);
    //     }
    //   }
    // };

    const QRCode = async (NowPrice) => {
      if (Object.keys(NowPrice).length > 0) {
        let TotalPrice = 0.0;
        const VAT_RATE = 0.07;
        const baseUrl = `${process.env.REACT_APP_API_SERVER}/PromptPayGenerate/`;
    
        try {
          if (NowPrice.Discount > 0) {
            switch (BillingType) {
              case 'Waybill':
                TotalPrice = parseFloat(NowPrice.IncludedDiscount) || 0;
                break;
              case 'TaxInvoice':
                TotalPrice = 0;
                break;
              case 'WaybillAndVatIncluded':
                TotalPrice = parseFloat(NowPrice.IncludedDiscount || 0) * (1 + VAT_RATE).toFixed(2);
                break;
              default:
                TotalPrice = parseFloat(NowPrice.IncludedDiscount) || 0;
            }
          } else {
            switch (BillingType) {
              case 'Waybill':
                TotalPrice = parseFloat(NowPrice.TotalPrice) || 0;
                break;
              case 'TaxInvoice':
                TotalPrice = 0;
                break;
              case 'WaybillAndVatIncluded':
                TotalPrice = parseFloat((NowPrice.TotalPrice || 0) * (1 + VAT_RATE).toFixed(2));
                break;
              default:
                TotalPrice = parseFloat(NowPrice.TotalPrice) || 0;
            }
          }
    
          const url = `${baseUrl}${TotalPrice.toString()}`;
          const response = await axios.get(url, { responseType: 'blob' });
    
          if (response.status === 200) {
            const imageUrl = URL.createObjectURL(response.data);
            setQrCode(imageUrl);
          }
        } catch (err) {
          console.error('Error fetching the QR code:', err);
          // Uncomment if using setError:
          // setError(err);
        }
      }
    };
    

    var PageWidth = '100%';
    var PageHeight = '100%';

    return (
      <>
        <div style={{ height: '100vh' }}>
          <PDFViewer style={{ width: PageWidth, height: PageHeight }}>
            {
              BillingType == "Waybill" ? (
                <PrintReceiptDocument BillingType={"Waybill"} QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "WaybillAndVatIncluded" ? (
                <PrintReceiptDocument BillingType={"WaybillAndVatIncluded"} QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "Receipt" ? (
                <PrintReceiptDocument BillingType={"Receipt"} QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "TaxInvoice" ? (
                <PrintTaxInvoice QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "SmallReceipt" ? (
                <PrintSmallReceipt QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : (
                <PrintReceiptDocument QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              )
            }
          </PDFViewer>
        </div>
      </>
    );
  };

  export default PrintReceipt;
