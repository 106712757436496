import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Font,PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import SarabunRegular from '../../Sarabun/Sarabun-Regular.ttf';
import SarabunBold from '../../Sarabun/Sarabun-Bold.ttf';
import PrintOldReceiptDocument from './PrintOldDocs';
import PrintOldTaxInvoice from './PrintOldTaxInvoice';

  const PrintOldReceipt = () => {
    const {BillingType, BillReference} = useParams();
    const [QrCode, setQrCode] = useState('');
    const [BillData, setBillData] = useState({
      "Status": true,
      "bill_id": "",
      "bill_reference": "",
      "bill_address": "",
      "bill_contact": "",
      "bill_name": "",
      "bill_payment": "",
      "bill_time": "",
      "bill_date": "",
      "bill_buyer_taxid": "",
      "st_id": null,
      "st_bill_ref": "",
      "st_status": "",
      "st_time": "",
      "st_date": "",
      "customer_id": null,
      "customer_name": "",
      "customer_bill_number": "",
      "customer_address": "",
      "customer_bill_file": "",
      "customer_balance": "",
      "customer_amount": "",
      "customer_phone": "",
      "customer_bill_reference": "",
      "customer_bill_date": "",
      "customer_bill_timestamp": "",
      "customer_note": null,
      "customer_payment_method": "",
      "bill_dis_amount": 0,
      "bill_dis_code": "",
      "bill_dis_id": 0,
      "bill_dis_ref": ""
    });
    const [NowPrice, setNowPrice] = useState({
        "TotalPrice": 0.00,
        "IncludedDiscount": 0.00,
        "Discount": 0.00
    });
    const [AllItemBag, setAllItemBag] = useState([]);
    const [Admin, setAdmin] = useState({});

    useEffect(() => {
      showHistory();
      showItemBag();
      RequestAdmin();
    }, [])
    

    const showHistory = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/OldBillCheckExist/${BillReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
            if(res.data.Status == true){
                setBillData(res.data);
            } 
            console.log(res.data);
            // else{
            //   window.location = '/sPosHome';
            // }
        console.log(res);
      } catch (err) {
        console.error('Error fetching bill history', err);
        // window.location = '/sPosHome';
      }
    };

    const showItemBag = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/OldBillItemBag/${BillReference}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        });
          if(res.data.InBagDetail.Status == true){
              setAllItemBag(res.data.InBagDetail.InBag);
              setNowPrice(res.data.Price);
              console.log(AllItemBag);
              QRCode(res.data.Price);
          } 
          console.log(res);
      } catch (err) {
        console.error('Error fetching bill history', err);
      }
    };

    const RequestAdmin = () => {
      const token = localStorage.getItem('token');
      if(token != null){
        axios({
          url: `${process.env.REACT_APP_API_SERVER}/authen`,
          method: "POST",
          headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
              "Authorization": "Bearer "+ token
          },
        })
        .then((res) => {
            // console.log(res.data.decoded);
            setAdmin(res.data.decoded);
        })
        .catch((err) => {
          console.error('Error checking session', err);
        })
      }
    }

    const QRCode = async (NowPrice) => {
      if (Object.entries(NowPrice).length > 0) {
        let TotalPrice = 0.00;
        var url = `${process.env.REACT_APP_API_SERVER}/PromptPayGenerate/`;
        if (NowPrice.Discount > 0) {
          if(BillingType == 'Waybill'){
            TotalPrice = parseFloat(NowPrice.IncludedDiscount);
          } else if(BillingType == 'TaxInvoice'){
            TotalPrice = '0';
          } else{
            TotalPrice = parseFloat(NowPrice.IncludedDiscount);
          }
        } else {
          if(BillingType == 'Waybill'){
            TotalPrice = parseFloat(NowPrice.TotalPrice);
          } else if(BillingType == 'TaxInvoice'){
            TotalPrice = '0';
          } else{
            TotalPrice = parseFloat(NowPrice.TotalPrice);
          }
          
        }
      
        try {
          url =  `${url}${TotalPrice}`;
          const response = await axios.get(url, { responseType: 'blob' });
          const imageUrl = URL.createObjectURL(response.data);
          setQrCode(imageUrl);
        } catch (err) {
          // setError(err);
          console.error('Error fetching the QR code:', err);
        }
      }
    };

    var PageWidth = '100%';
    var PageHeight = '100%';

    return (
      <>
        <div style={{ height: '100vh' }}>
          <PDFViewer style={{ width: PageWidth, height: PageHeight }}>
            {
              BillingType == "Waybill" ? (
                <PrintOldReceiptDocument BillingType={"Waybill"} QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "Receipt" ? (
                <PrintOldReceiptDocument BillingType={"Receipt"} QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : BillingType == "TaxInvoice" ? (
                <PrintOldTaxInvoice QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              ) : (
                <PrintOldReceiptDocument QRCode={QrCode} Admin={Admin} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} NowPrice={NowPrice} CountItemAmountAll={AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0)} />
              )
            }
          </PDFViewer>
        </div>
      </>
    );
  };

  export default PrintOldReceipt;
