import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import SweetAlert from './SweetAlert';
import ShortText from './Text';


function ShippingStates() {
    const [StatusQuantity, setStatusQuantity] = useState([]);

    const GetStatusQuantity = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/GetStatusQuantity/Array`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
          if(res.status == 200){
            if(res.data.Status == true){
              console.log(res.data.Data);
              if(res.data.Data.length >= 5){
                SweetAlert('error', 'ใช้งานไม่ได้ชั่วคราว', 'กรุณาอัพเดทสถานะการขนส่ง')
              }
              setStatusQuantity(res.data.Data);
            }
          }
        } catch (err) {
          console.error('Error searching bill history', err);
        }
    };

    useEffect(() => {
      GetStatusQuantity();
    }, [])
    

  return (
    <>
        <div className="row">
            <label htmlFor="">กรุณาอัพเดทสถานะการขนส่ง <div className='text-danger d-inline'>({StatusQuantity.length} รายการ)</div></label>
            <div className="col-12 scrollspy-example scrollspy-height-100 scroll-with-hide-bar">
                {
                    StatusQuantity.map(List => (
                        <>
                            <Link className="btn btn-danger col-12 mb-2 text-start" to={`/sPos/${List.bill_reference}`}>
                                <i className="bi bi-person-exclamation"/> 
                                { ` `+ShortText(List.bill_name, 30) }
                            </Link>
                        </>
                    ))
                }
            </div>
        </div>
    </>
  )
}

export default ShippingStates