import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import SarabunRegular from '../../Sarabun/Sarabun-Regular.ttf';
import SarabunBold from '../../Sarabun/Sarabun-Bold.ttf';
import BillNumber from '../BillNumber.js';
import ConvertToThai from '../ConvertToThai.js'
import ToThaiTotal from '../ReFormat/ToThaiTotal.js';
import DateThaiFormat from '../ReFormat/DateThaiFormat.js';


  Font.register({
    family: 'Sarabun',
    fonts: [
      { src: SarabunRegular, fontStyle: 'normal', fontWeight: 'normal' },
      { src: SarabunBold, fontStyle: 'normal', fontWeight: 'bold' },
      ],
    });


  const styles = StyleSheet.create({
    body: {
      padding: 5,
      fontSize: 8,
      fontFamily: 'Sarabun',
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeader: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
    },
    tableColHeaderNumber: {
      width: "5%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
    },
    tableColHeaderList2: {
        width: "40%",
        // borderLeftWidth: 0.50,
        // borderRightWidth: 0.50,
        // borderBottomWidth: 0,
        // borderTopWidth: 0,
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderList: {
        // borderBottomWidth: 0.50,
        // borderTopWidth: 0.50,
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderAmountAndUnit: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
    },
    tableCol: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      textAlign: "center",
      padding: 2,
    },
    tableCellHeader: {
      fontSize: 8,
      fontWeight: 'bold',
    },
    tableCell: {
      fontSize: 8,
    },
    footer: {
      marginTop: 10,
      textAlign: 'right',
      fontWeight: 'bold',
    },
    totalRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 2,
    },
    tableColNumber:{
        // borderLeftWidth: 0.50,
        // borderRightWidth: 0.50,
        width: "5%",
        borderStyle: "solid",
        // borderWidth: 0.50, 
        backgroundColor: "#ffffff",
        borderColor: '#ffffff',
        textAlign: "center",
        padding: 2,
    },
    totalCell: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      textAlign: "center",
      padding: 2,
    },
    tableList: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      textAlign: "center",
      padding: 2,
    },
    headerText: {
        fontFamily: 'Sarabun',
        fontSize: 8,
        marginBottom: 5,
    },
    headerBill: {
        fontFamily: 'Sarabun',
        fontSize: 13,
        marginBottom: 5,
    },
  });

  const List = StyleSheet.create({
    tableColHeaderNumber_Normal: {
        width: "5%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderList_Normal: {
        width: "40%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        // borderBottomWidth: 0,
        // borderTopWidth: 0,
        borderStyle: "solid",
        // borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderAmountAndUnit_Normal: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
      },
    tableCol_Normal: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        textAlign: "center",
        padding: 2,
      },
    tableColHeaderNumber_LR: {
        width: "5%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderList_LR: {
        width: "40%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderAmountAndUnit_LR: {
        width: "20%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableCol_LR: {
        width: "20%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderNumber_LR: {
        width: "5%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
      },
    tableColHeaderList_LR: {
        width: "40%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderAmountAndUnit_LR: {
        width: "20%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableCol_LR: {
        width: "20%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderNumber_LR: {
      width: "5%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
    },
    tableColHeaderNumber_LR_T: {
        width: "5%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderList_LR_T: {
        width: "40%",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 2,
    },
    tableColHeaderAmountAndUnit_LR_T: {
        width: "20%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 2,
    },
    tableCol_LR_T: {
        width: "20%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#ffffff',
        textAlign: "center",
        padding: 2,
    },
    tableColHeaderNumber_LR_T: {
      width: "5%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
  },
  tableColHeaderList_LR_T: {
      width: "40%",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderStyle: "solid",
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "left",
      padding: 2,
  },
  tableColHeaderAmountAndUnit_LR_T: {
      width: "20%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
  },
  tableCol_LR_T: {
      width: "20%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#ffffff',
      textAlign: "center",
      padding: 2,
  },
  tableColHeaderNumber_LR_T: {
    width: "5%",
    borderStyle: "solid",
    borderTopWidth: 0.50,
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderColor: '#ffffff',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 2,
  },
  tableColHeaderList_LR_B: {
      width: "40%",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderStyle: "solid",
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "left",
      padding: 2,
  },
  tableColHeaderAmountAndUnit_LR_B: {
      width: "20%",
      borderStyle: "solid",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 2,
  },
  tableCol_LR_B: {
      width: "20%",
      borderStyle: "solid",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#ffffff',
      textAlign: "center",
      padding: 2,
  },
  tableColHeaderNumber_LR_B: {
    width: "5%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#ffffff',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 2,
  },
  tableColHeaderList_LR_B: {
    width: "40%",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderStyle: "solid",
    borderColor: '#ffffff',
    backgroundColor: "#ffffff",
    textAlign: "left",
    padding: 2,
  },
  tableColHeaderAmountAndUnit_LR_B: {
    width: "20%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#ffffff',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 2,
  },
  tableCol_LR_B: {
    width: "20%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#ffffff',
    textAlign: "center",
    padding: 2,
  },
  });

  const HeaderTable = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      borderCollapse: "collapse",
      textAlign: "left",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeaderInfomation: {
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      padding: 1,
    },
    tableCol: {
      width: "20%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      padding: 2,
    },
    tableCellHeader: {
      fontSize: 8,
    },
    tableCell: {
      fontSize: 8,
      marginTop: 2
    },
    tableCellList: {
        fontSize: 8,
        textAlign: 'left'
      },
    footer: {
      marginTop: 10,
      textAlign: 'right',
      fontWeight: 'bold',
    },
    tableColHeaderAddress:{
      width: "75%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      paddingLeft: 5,
      paddingTop: 4
    }
  });

  const LogoTable = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        borderCollapse: "collapse",
        textAlign: "left",
      },
      tableRow: {
        flexDirection: "row",
      },
      tableColHeaderLogo: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        padding: 1,
        alignItems: 'center',
        textAlign: 'center'
      },
      tableCol: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        padding: 2,
      },
      tableCellHeader: {
        fontSize: 8,
      },
      tableCell: {
        fontSize: 8,
      },
      footer: {
        marginTop: 10,
        textAlign: 'right',
        fontWeight: 'bold',
      },
      tableColHeaderBarcode:{
        width: "50%",
        borderStyle: "solid",
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: 'right',
        alignItems: 'flex-end',
        marginright: 'auto',
        padding: 2

      }
  });

  const QRCodeStyle = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        borderCollapse: "collapse",
        textAlign: "left",
        marginTop: 10
      },
      tableRow: {
        flexDirection: "row",
      },
      tableColHeaderQRCode: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        padding: 3,
        alignItems: 'center'
      },
      tableColHeaderQRCodeDetail: {
        width: "100%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        marginLeft: 1,
      },
      tableColHeaderQRCodeDetailPadding: {
        paddingTop: 1
      },
      tableCol: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        marginLeft: 5,
      },
      tableColHeaderQRText: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        // padding: 2,
      },
      headerText: {
        fontFamily: 'Sarabun',
        fontSize: 8,
        marginBottom: 3,
      },
      SignText: {
        fontFamily: 'Sarabun',
        fontSize: 8,
        marginTop: 5,
        textAlign: 'center'
      },
      QRCodePriceText: {
        fontSize: 20
      }

    });

  function PrintSmallReceipt({QRCode, Admin, BillReference, BillData, AllItemBag, NowPrice, CountItemAmountAll}) {
    

    const mmToPoints = (mm) => mm * 2.83465;
    const widthInMm = 80;  // A4 width in mm
    const heightInMm = 3000; // A4 height in mm
  
    const widthInPoints = mmToPoints(widthInMm);
    const heightInPoints = mmToPoints(heightInMm);

    return (
      <Document>
        <Page style={styles.body} size={{ width: widthInPoints, height: heightInPoints }}>
          <View style={LogoTable.table}>
              <View style={LogoTable.tableRow}>
              {/* <Image src={process.env.PUBLIC_URL + "/assets/smkconcretelogo.png"} style={{width: '100%'}}/> */}

                  <View style={LogoTable.tableColHeaderLogo}>
                      <Image src={process.env.PUBLIC_URL + "/assets/smkconcretelogo.png"} style={{width: '60%'}}/>
                  </View>
              </View>
          </View>

          <View style={HeaderTable.table}>
              <View style={HeaderTable.tableRow}>
                    <View style={LogoTable.tableColHeaderLogo}>
                        <Text style={styles.headerBill}> {"ใบเสร็จรับเงิน"} </Text>
                        <Text style={styles.headerText}> {"ห้างหุ้นส่วนจำกัดสมศักดิ์คอนกรีต (สำนักงานใหญ่)"} </Text>
                        <Text style={styles.headerText}> {"75/6 ม.2 ต.บางสวรรค์ อ.พระแสง จ.สุราษฎร์ธานี 84210"} </Text>
                        <Text style={styles.headerText}> {"โทรศัพท์ 07-7365401, 08-5784-1509"} </Text>
                        <Image src={"https://bwipjs-api.metafloor.com/?bcid=code128&text="+BillData.bill_reference} style={{width: "70%", height: 10 }} />
                        <Text style={styles.headerText}> 
                            {BillNumber(parseInt(BillData.bill_id))}
                        </Text>
                    </View>
              </View>
          </View>

            <View style={HeaderTable.table}>
              <View style={HeaderTable.tableRow}>
                <View style={HeaderTable.tableColHeaderInfomation}>
                    <Text style={HeaderTable.tableCell}> {"ผู้ซื้อสินค้า : "} {BillData.bill_name} </Text>
                    <Text style={HeaderTable.tableCell}> {"ผู้ทำรายการ : "} {Admin.user_firstname+ " " +Admin.user_lastname} </Text>
                    <Text style={HeaderTable.tableCell}> {"วันที่ออกเอกสาร : "} { DateThaiFormat() } </Text>
                </View>
              </View>


            </View>
            
            <View style={{marginTop: 10}}> </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                {/* <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}> {"ที่"} </Text>
                </View> */}
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCellHeader}> {"รายการ"} </Text>
                </View>
                {/* <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCellHeader}> {"หน่วยนับ"} </Text>
                </View> */}
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCellHeader}> {"จำนวน"} </Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}> {"ราคา"} </Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}> {"รวม"} </Text>
                </View>
              </View>


              {AllItemBag.map((item, index) => (
                <View key={item.bag_id} style={styles.tableRow}>
                  {
                      AllItemBag.length == 1 ? (
                          <>
                              {/* <View style={styles.tableColHeaderNumber}>
                                  <Text style={styles.tableCell}> {index + 1} </Text>
                              </View> */}
                              <View style={styles.tableColHeaderList2}>
                                  <Text style={styles.tableCellList}> {item.bag_item_name} ({item.bag_item_unit}) </Text>
                              </View>
                              {/* <View style={styles.tableColHeaderAmountAndUnit}>
                                  <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                              </View> */}
                              <View style={styles.tableColHeaderAmountAndUnit}>
                                  <Text style={styles.tableCell}> x{parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                              <View style={styles.tableCol}>
                                  <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                              <View style={styles.tableCol}>
                                  <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                          </>
                      ) : AllItemBag.length > 1 ? (
                            (index + 1) == 1 ? (
                              <>
                                {/* <View style={List.tableColHeaderNumber_LR_T}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View> */}
                                <View style={List.tableColHeaderList_LR_T}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} ({item.bag_item_unit}) </Text>
                                </View>
                                {/* <View style={List.tableColHeaderAmountAndUnit_LR_T}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View> */}
                                <View style={List.tableColHeaderAmountAndUnit_LR_T}>
                                    <Text style={styles.tableCell}> x{parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_T}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_T}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            ) : (index + 1) == AllItemBag.length ? (
                              <>
                                {/* <View style={List.tableColHeaderNumber_LR_B}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View> */}
                                <View style={List.tableColHeaderList_LR_B}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} ({item.bag_item_unit}) </Text>
                                </View>
                                {/* <View style={List.tableColHeaderAmountAndUnit_LR_B}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View> */}
                                <View style={List.tableColHeaderAmountAndUnit_LR_B}>
                                    <Text style={styles.tableCell}> x{parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_B}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_B}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            ) : (
                              <>
                                {/* <View style={List.tableColHeaderNumber_LR}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View> */}
                                <View style={List.tableColHeaderList_LR}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} ({item.bag_item_unit}) </Text>
                                </View>
                                {/* <View style={List.tableColHeaderAmountAndUnit_LR}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View> */}
                                <View style={List.tableColHeaderAmountAndUnit_LR}>
                                    <Text style={styles.tableCell}> x{parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            )
                          
                      ) : null
                  }
                </View>
              ))}

              <View style={styles.tableRow}>
                {/* <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}> {" "} </Text>
                </View> */}
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}> {"สินค้า " + AllItemBag.length + " รายการ"} </Text>
                </View>
                {/* <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"รวมเงิน"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> { parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) } </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                {/* <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}> จำนวน {CountItemAmountAll.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} ชิ้น </Text>
                </View>
                {/* <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"ส่วนลด"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {NowPrice.Discount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                {/* <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}></Text>
                </View>
                {/* <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"หักส่วนลด"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> 
                  {
                      NowPrice.Discount > 0 ? (
                          <>{parseFloat(NowPrice.IncludedDiscount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      ) : (
                          <>{parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      )
                  }
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                {/* <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}>
                    {ConvertToThai(ToThaiTotal(NowPrice))}
                  </Text>
                </View>
                {/* <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View> */}
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"ราคาสุทธิ"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> 
                  {
                      NowPrice.Discount > 0 ? (
                          <>{parseFloat(NowPrice.IncludedDiscount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      ) : (
                          <>{parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      )
                  }
                  </Text>
                </View>
              </View>

            </View>

            <View style={QRCodeStyle.table}>
              <View style={QRCodeStyle.tableRow}>
                <View style={QRCodeStyle.tableColHeaderQRCode}>
                    {/* <PromptPayQRCode mobileNumber={"0857841509"} amount={500} /> */}
                    {/* <Image src={process.env.PUBLIC_URL + "/assets/QRPromptPay/QRCode.png"} style={{width: 70}} /> */}
                    {/* <Image src={'https://promptpay.io/0857841509/100'} style={{width: 70}} /> */}
                    <Image src={QRCode} style={{width: '40%'}}/>
                    {/* <Image src={"http://promptpay.io/0857841509/"+(parseFloat(NowPrice.TotalPrice)).toString()} style={{width: "40%"}}/> */}
                </View>
            </View>
            <View style={QRCodeStyle.tableRow}>
              <View style={QRCodeStyle.tableColHeaderQRCode}>
                    <Text style={QRCodeStyle.QRCodePriceText}>
                      {
                          NowPrice.Discount > 0 ? (
                              <>{parseFloat(NowPrice.IncludedDiscount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                          ) : (
                              <>{parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                          )
                      }
                    </Text>
                  </View>
              </View>
          </View>
          

        </Page>
      </Document>
    );
  }




  export default PrintSmallReceipt;
