import React, { useState, useEffect, useRef } from 'react';
import NavBar from './NavBar';
import axios from 'axios';
import Authen from '../Validation/Authen';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useLocation, useNavigate, useParams, useSearchParams, Link} from "react-router-dom";
import BillNumber from '../Function/BillNumber';
import ShortText from '../Function/Text';
import VerifyModal from './Modal/VerifyModal';
import PrintModal from './Modal/PrintModal';
import CustomerEditModal from './Modal/CustomerEditModal'
import AddItemBag from '../Function/AddItemBag';
import Discount from '../Function/Discount';
import SearchProductList from '../Function/SearchProductList';
import ImageUpload from './Modal/ImageUpload';
import { Button } from 'react-bootstrap';
import ImportProductFromAnotherBill from './Modal/ImportProductFromAnotherBill';


export default function Pos() {
    let location = useLocation();
    let navigate = useNavigate();
    let {BillReference} = useParams();
    const [BillReferenceExisted, setBillReferenceExisted] = useState("");
    const [BillData, setBillData] = useState({
        "Status": true,
        "bill_id": "",
        "bill_reference": "",
        "bill_address": "",
        "bill_contact": "",
        "bill_name": "",
        "bill_payment": "",
        "bill_time": "",
        "bill_date": "",
        "bill_buyer_taxid": "",
        "st_id": null,
        "st_bill_ref": "",
        "st_status": "",
        "st_time": "",
        "st_date": "",
        "customer_id": null,
        "customer_name": "",
        "customer_bill_number": "",
        "customer_address": "",
        "customer_bill_file": "",
        "customer_balance": "",
        "customer_amount": "",
        "customer_phone": "",
        "customer_bill_reference": "",
        "customer_bill_date": "",
        "customer_bill_timestamp": "",
        "customer_note": null,
        "customer_payment_method": "",
        "bill_dis_amount": 0,
        "bill_dis_code": "",
        "bill_dis_id": 0,
        "bill_dis_ref": ""
    });

    const [ProcessingUserInformation, setProcessingUserInformation] = useState({});
    const [NowPrice, setNowPrice] = useState({
        "TotalPrice": 0.00,
        "IncludedDiscount": 0.00,
        "Discount": 0.00
    });
    const [AllItemBag, setAllItemBag] = useState([]);
    const [AllItemBagReturn, setAllItemBagReturn] = useState([]);
    const [ItemDeleted, setItemDeleted] = useState({});
    const [ItemChange, setItemChange] = useState({});
    const [clicked, setClicked] = useState(false);
    // const [CountItemAmountAll, setCountItemAmountAll] = useState(0);
    const LimitKeyInput = (event) => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', '.'];
        const isNumberKey = event.key >= '0' && event.key <= '9';
    
        if (!allowedKeys.includes(event.key) && !isNumberKey) {
            event.preventDefault();
        }
    };


    const showHistory = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillCheckExist/${BillReference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
              if(res.data.Status == true){
                  setBillReferenceExisted(BillReference);
                  setBillData(res.data);
                  console.log(res.data);
              } else{
                  navigate('/sPosHome');
              }
        //   console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        //   navigate('/sPosHome');
        }
      };


    const showItemBag = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillItemBag/${BillReference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            if(res.data.InBagDetail.Status == true){
                setAllItemBag(res.data.InBagDetail.InBag);
                setNowPrice(res.data.Price);
                var totalAmount = 0;
                // let totalAmount = AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0);
                AllItemBag.forEach(item => {
                    totalAmount += parseFloat(item.bag_item_amount);
                });
                // setCountItemAmountAll(totalAmount);
                // console.log(res.data.InBagDetail.InBag);
            } 
            // console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
    };

    const showReturnItemBag = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillItemBagReturn/${BillReference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            if(res.data.InBagReturnDetail.Status == true){
                setAllItemBagReturn(res.data.InBagReturnDetail.InBag);
                showItemBag();
            } 
            // console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
    };
    

    const DeleteItem = async (ItemRefNumber) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/DeleteItemFromBag/${BillReference}/${ItemRefNumber}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
            if(res.data.Status == true){
                setItemDeleted(res.data);
                showItemBag();
            } 
            // console.log(res);
        } catch (err) {
            console.error('Error fetching bill history', err);
        }
    };

    const ReturnItem = async (ItemRefNumber) => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/ReturnItem/${BillReference}/${ItemRefNumber}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            if(res.data.Status == true){
                showReturnItemBag();
            } 
            // console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
    };

    const ChangeDetailItem = async (e, ItemRefNumber, ItemAmount, ItemPrice) => {
        e.preventDefault();
        if((ItemRefNumber != null && ItemAmount > 0 && ItemPrice > 0) && BillData.st_status == "process"){
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/ChangeItemDetailFromBag`, {
                    BillReference: BillReference,
                    ItemReference: ItemRefNumber,
                    ItemAmount: ItemAmount,
                    ItemPrice: ItemPrice
                },{
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });
                if(res.data.Status == true){
                    setItemChange(res.data);
                    showItemBag();
                    // console.log(res.data);
                } 
                // console.log(res);
            } catch (err) {
                console.error('Error fetching bill history', err);
            }
        }
    };

    useEffect(() => {
        // Authentication
        const token = localStorage.getItem('token');
        if (token) {
            try{
                const GetUserInformation = async () => {
                    const response = await axios({
                        url: `${process.env.REACT_APP_API_SERVER}/getUserInformation`,
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                        },
                    });
                    console.log(response);
                    setProcessingUserInformation(response.data.user);
                }
                GetUserInformation();
            } catch(err){
                window.location = '/';
            }
        } else {
            window.location = '/';
        }
        // Authentication
        // showHistory();
        // showItemBag();
        // showReturnItemBag();

            // Initial mount
            showHistory();
            showItemBag();
            showReturnItemBag();
            
            
            if (clicked == true) {
                showHistory();
                showReturnItemBag();
                showItemBag();
                setClicked(false);
              }
      }, [clicked]);

      const handleClick = () => {
        console.log('Component clicked');
        setTimeout(() => {
            setClicked(true);
        }, 100);
      };
    


      const forwardDays = (fromday, day) => {
        const inputTimestamp = new Date(fromday).getTime();
        const newTimestamp = inputTimestamp + (day * 24 * 60 * 60 * 1000);
        const newDate = new Date(newTimestamp).toISOString().split('T')[0];
        return newDate;
      };

    //   const TodayDateResult = new Date(moment().locale('TH').format("YYYY-MM-DD")).toLocaleDateString('th-TH', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     weekday: 'long',
    //   })

      const handleDeleteItemFromBag = (dataItem) => {
        // console.log(dataItem);
        if(BillData.st_status == "process"){
            DeleteItem(dataItem);
        }

      }

      const handleReturnItem = (dataItem) => {
        if(BillData.st_status == "success"){
            ReturnItem(dataItem);
        }
      }

      const handleClickWithTimer = () => {
        setTimeout(() => {
            setClicked(true);
        }, 1000);
      }
      
      const [onHover, setonHover] = useState(false);


  return (
    <>
        <NavBar />
        {/* {location.pathname.indexOf['/']} */}
        <div className="container-fluid mt-2 px-4" onClick={handleClick}>
            <div className="row" style={{marginTop: '1rem'}}>
                <div className="col-md-4">
                    <div className="row" style={{marginTop: '1rem'}}>
                        <div className="col-6">
                            <Link to={'/SalesHistory'} className="btn btn-danger col-12">
                                <i className="bi bi-file-earmark-spreadsheet"/> รายงานการขาย
                            </Link>
                        </div>
                        <div className="col-6">
                            <Link to="/Logout" className="btn btn-info col-12" onMouseOver={() => setonHover(true)} onMouseLeave={() => setonHover(false)}>
                                {
                                    onHover ? (
                                        <>
                                            <i className="bi bi-person-fill-gear" />
                                            {` เปลี่ยนผู้ใช้`}
                                        </>
                                    ) : (
                                        <>
                                            <i className="bi bi-person-gear" /> 
                                            {ShortText(` @`+ProcessingUserInformation.member_username, 35)}
                                        </>
                                    )
                                }
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '0.5rem'}}>
                        <div className="col-6">
                            <CustomerEditModal BillData={BillData} />
                        </div>
                        <div className="col-6">
                            {
                                BillData.st_status == 'process' ? (
                                    <ImportProductFromAnotherBill ToBillReference={BillReference} UpdateStatus={() => setClicked(true)} />
                                ) : (
                                    <button className='btn btn-success col-12' disabled={true}>
                                        <i className="bi bi-file-earmark-arrow-down" /> นำเข้ารายการสินค้า
                                    </button>
                                )
                            }
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '0.5rem'}}>
                        <div className="col-6">
                            <VerifyModal onClick={handleClick} BillData={BillData}></VerifyModal>
                        </div>
                        <div className="col-6">
                            <ImageUpload BillReference={BillReference} BillData={BillData} />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '0.5rem'}}>
                        <div className="col-6">
                            <PrintModal Price={NowPrice} BillReference={BillReference} AllItemBag={AllItemBag} BillData={BillData} />
                        </div>
                        <div className="col-6">
                            <Link to="/sPosHome" className="btn btn-success col-12" replace>
                                <i className="bi bi-clock-history" /> ประวัติการขาย
                            </Link>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: '0.5rem'}}>
                        <div className="col-6">
                            <button type="button" className="btn btn-outline-dark col-12">
                                สถานะสินค้า {AllItemBag.length} รายการ
                            </button>
                        </div>
                        <div className="col-6">
                            <a className="btn btn-outline-dark col-12">
                                สถานะจำนวน { AllItemBag.reduce((sum, item) => sum + parseFloat(item.bag_item_amount), 0).toFixed(2) } ชิ้น
                            </a>
                        </div>
                    </div>
                    <Discount BillReference={BillReference} BillData={BillData} AllItemBag={AllItemBag} NowPrice={NowPrice} />
                </div>
                <div className="col-md-6">
                    <div className="card card-body pt-4 card-colorsmk-blink-nothover" style={{marginTop: '1rem'}}>
                        <h1 align="center" className='smktitle h1-oversize'>
                            {parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            {
                                NowPrice.Discount > 0 ? (
                                    <div style={{display: 'inline', fontSize: '1rem'}} className="text-danger"> -{NowPrice.Discount}</div>
                                ) : null
                            }
                        </h1>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card" align="right">
                                <div className="card-body">
                                <div className="card-header p-0 mb-3">
                                    <h5 className="card-title text-left mb-2">
                                        <i className="bi bi-info-circle" /> ข้อมูลการทำรายการ
                                    </h5>
                                </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button variant='outline-secondary' className='col-12 mb-2'>
                                                <i className="bi bi-receipt" /> {BillNumber(parseInt(BillData.bill_id))}
                                            </Button>
                                            <Button variant='outline-secondary' className='col-12 mb-2'>
                                                {
                                                    BillData.st_status == "process" ? (
                                                        <><i className="bi bi-cart-plus" /> กำลังทำรายการ</>
                                                    ) : BillData.st_status == "pause" ? (
                                                        <><i className="bi bi-x" /> ยกเลิกการขาย</>
                                                    ) : BillData.st_status == "success" ? (
                                                        <><i className='bi bi-check2'/> การขายเสร็จสิ้นแล้ว</>
                                                    ) : null
                                                }
                                            </Button>
                                            <Button variant='outline-secondary' className='col-12 mb-2'>
                                                <i className="bi bi-person" /> {ShortText(BillData.bill_name, 15)}
                                            </Button>
                                            <Button variant='outline-secondary' className='col-12 mb-2'>
                                                {
                                                    BillData.bill_payment == "banktransfer" ? (
                                                        <>
                                                            <i className="bi bi-bank" /> อินเตอร์เน็ตแบงก์
                                                        </>
                                                    ) : BillData.bill_payment == "cash" ? (
                                                        <>
                                                            <i className="bi bi-cash-coin" /> เงินสด
                                                        </>
                                                    ) : null
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <hr style={{marginTop: '1rem'}} />
                    <SearchProductList BillData={BillData} BillReference={BillReference} AllItemBag={AllItemBag} />
                </div>
                <div className="col-md-8">
                    <hr style={{marginTop: '1rem'}} />
                    <AddItemBag onClick={handleClickWithTimer} BillData={BillData} BillReference={BillReference} AllItemBag={AllItemBag}/>
                    <div className="col-md-12" key="TableHeader">
                        <div className={(AllItemBag.length + AllItemBagReturn.length > 6) ? "row scrollspy-example scrollspy-pos" : "row"} key="Header">
                        {   
                            AllItemBag.slice().reverse().map((Value, id) => (
                                <div className='col-md-6' key={Value.bag_id}>
                                    <div className="card">
                                    <div className="card-body p-3">
                                        <div className="row mb-2">
                                            <div className="col-9">
                                                <h5 className='text-left card-title'>
                                                    {ShortText(`${Value.bag_item_name}`, 30)}
                                                </h5>
                                            </div>
                                            <div className="col-3 text-end">
                                                <span className="badge text-bg-success">
                                                    {/* #{Value.bag_item_barcode} */}
                                                    {(parseFloat(Value.bag_item_price) * parseFloat(Value.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท
                                                </span>
                                            </div>
                                            <div className="col-md-12 text-secondary">
                                                #{Value.bag_item_barcode}
                                            </div>
                                        </div>
                                        <div className="row" align="center">
                                            {
                                                BillData.st_status == "process" ? (
                                                    <>
                                                        <div className="col-6 text-start">
                                                            <label htmlFor="">
                                                                <small>จำนวน ({Value.bag_item_unit})</small>
                                                            </label>
                                                            <input type="text" className="form-control text-center" defaultValue={Value.bag_item_amount} onChange={(e) => ChangeDetailItem(e, Value.bag_item_ref, e.target.value, Value.bag_item_price)} onKeyDown={LimitKeyInput} />
                                                        </div>
                                                        <div className="col-6 text-start">
                                                            <label htmlFor="">
                                                                <small>ราคาต่อหน่วย</small>
                                                            </label>
                                                            <input type="text" className="form-control text-center" defaultValue={Value.bag_item_price} onChange={(e) => ChangeDetailItem(e, Value.bag_item_ref, Value.bag_item_amount, e.target.value)} onKeyDown={LimitKeyInput} />
                                                        </div>
                                                    </>
                                                ) : BillData.st_status == "success" || BillData.st_status == "pause" ? (
                                                    <>
                                                        <div className="col-6 text-start">
                                                            <label htmlFor="">
                                                                <small>จำนวน ({Value.bag_item_unit})</small>
                                                            </label>
                                                            <input type="text" className="form-control text-center" disabled defaultValue={Value.bag_item_amount} onKeyDown={LimitKeyInput} />
                                                        </div>
                                                        <div className="col-6 text-start">
                                                            <label htmlFor="">
                                                                <small>ราคาต่อหน่วย</small>
                                                            </label>
                                                            <input type="text" className="form-control text-center" disabled defaultValue={Value.bag_item_price} onKeyDown={LimitKeyInput} />
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                            {/* <div className="col-md-1">
                                                {(parseFloat(Value.bag_item_price) * parseFloat(Value.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </div> */}
                                            <div className="col-md-12 mt-3">
                                                {
                                                    BillData.st_status == "success" ? (
                                                        <>
                                                            <button onClick={() => handleReturnItem(Value.bag_item_ref)} className="btn btn-danger col-12">
                                                                <i className="bi bi-arrow-counterclockwise" /> คืนสินค้า
                                                            </button>
                                                        </>
                                                    ) : BillData.st_status == "process" ? (
                                                        <>
                                                            <button onClick={() => handleDeleteItemFromBag(Value.bag_item_ref)} className="btn btn-outline-danger col-12">
                                                                <i className="bi bi-trash" /> ลบสินค้า
                                                            </button>
                                                        </>
                                                    ) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    </div>      
                                </div>
                            ))
                        }
                        {
                            AllItemBagReturn.length > 0 ? (
                                <h4 className="ms-2 mt-4">รายการคืนสินค้า</h4>
                            ) : null
                        }
                        {   
                            AllItemBagReturn.map((Value, id) => (
                                <>
                                    <div className='col-md-6' key={Value.bag_id}>
                                        <div className="card">
                                        <div className="card-body p-3">
                                            <div className="row mb-2">
                                                <div className="col-md-9">
                                                    <h5 className='text-left card-title'>
                                                        {ShortText(`${Value.bag_item_name}`, 30)}
                                                    </h5>
                                                </div>
                                                <div className="col-md-3 text-end">
                                                    <span className="badge text-bg-success">
                                                        {/* #{Value.bag_item_barcode} */}
                                                        {(parseFloat(Value.bag_item_price) * parseFloat(Value.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท
                                                    </span>
                                                </div>
                                                <div className="col-md-12 text-secondary">
                                                    #{Value.bag_item_barcode}
                                                </div>
                                            </div>
                                            <div className="row" align="center">
                                                <>
                                                    <div className="col-md-6 text-start">
                                                        <label htmlFor="">
                                                            <small>จำนวน ({Value.bag_item_unit})</small>
                                                        </label>
                                                        <input type="text" className="form-control text-center" disabled defaultValue={Value.bag_item_amount} onKeyDown={LimitKeyInput} />
                                                    </div>
                                                    <div className="col-md-6 text-start">
                                                        <label htmlFor="">
                                                            <small>ราคาต่อหน่วย</small>
                                                        </label>
                                                        <input type="text" className="form-control text-center" disabled defaultValue={Value.bag_item_price} onKeyDown={LimitKeyInput} />
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </div>      
                                </div>
                                </>
                            ))
                        }
                        </div>
                    </div>
                </div>


            </div>
        </div>

        
    </>
  )
}
