import { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ShortText from '../../Function/Text';
import moment from 'moment';

const ExportOldData = ({CancelTimeout}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
        if(typeof CancelTimeout === 'function'){
            CancelTimeout()
        }
        handleSearch('', false);
    };
    const navigate = useNavigate();
    const [DataLists, setDataLists] = useState([]);
    const [BillingSelected, setBillingSelected] = useState('');


    const handleSearch = async (e, HasKeyword) => {
        let Values = {};
        if(HasKeyword){
            e.preventDefault();
            Values = {
                SearchingKeyword: e.target.value,
                HasKeyword: HasKeyword
            }
        } else{
            Values = {
                HasKeyword: HasKeyword
            }
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/SearchOldBilling`, Values, 
            {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                },
            });
            console.log(res);
            if(res.status == 200){
                setDataLists(res.data.Data);
            }
        } catch (err) {
            console.error('Error searching bill history', err);
        }
    };


    useEffect(() => {
        
    }, [])

    
    

    return (
        <>
        {
            (typeof CancelTimeout === 'function') ? (
                <div className="frame-content text-bg-primary mb-3 p-3 cursor-pointer" onClick={handleShow}>
                    <div className="d-flex">
                        <h1 className="ps-3 h1-oversize text-light">
                            <i className="bi bi-file-earmark-bar-graph-fill"></i>
                        </h1>
                        <h3 className="ps-3 text-light sarabun">
                            ข้อมูลเก่า
                        </h3>
                    </div>
                </div>
            ) : (
                <div className='nav-link-sidebar link-body-emphasis cursor-pointer' onClick={handleShow}>
                    <i className="bi bi-file-earmark-bar-graph-fill"/> ข้อมูลเก่า
                </div>
            )
        }
            <Modal centered show={show} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">ค้นหารายการในอดีต</h5>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container">
                        <input type="text" className="form-control" placeholder='พิมพ์คำค้นหาที่นี่' onKeyUp={(e) => handleSearch(e, true)} />

                        <div className="pt-3">
                            <div className="row">
                                {
                                    DataLists.map((bill) => (
                                        <div className='col-md-4' key={bill.bill_id}>
                                            <div className={`card card-colorsmk-blink cursor-pointer ${BillingSelected == bill.bill_reference && 'card-colorsmk-blink-nothover'}`} onClick={() => setBillingSelected(bill.bill_reference)}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-9">
                                                            <h6 className="card-title" align="left">
                                                                {ShortText(bill.bill_name, 35)}
                                                            </h6>
                                                        </div>
                                                        <div className="col-3" align="right">
                                                            <span className='badge rounded-pill text-bg-primary'>
                                                                {`#${bill.bill_id}`}
                                                            </span>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <button className="btn btn-light col-12">
                                                                {
                                                                    moment(bill.bill_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    

                </Modal.Body>
                {/* <Modal.Footer> */}
                    <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                        {
                            BillingSelected && (
                                <div className="row mb-3">
                                    <div className="col-4">
                                        <Link target='_blank' to={`/PrintOldReceipt/TaxInvoice/${BillingSelected}`} className='btn btn-success col-12'>
                                            <i className="bi bi-file-earmark-binary"/> ใบกำกับภาษี
                                        </Link>
                                    </div>
                                    <div className="col-4">
                                        <Link target='_blank' to={`/PrintOldReceipt/Receipt/${BillingSelected}`} className='btn btn-success col-12'>
                                            <i className="bi bi-file-earmark-binary"/> ใบเสร็จรับเงิน
                                        </Link>
                                    </div>
                                    <div className="col-4">
                                        <Link target='_blank' to={`/PrintOldReceipt/Waybill/${BillingSelected}`} className='btn btn-success col-12'>
                                            <i className="bi bi-file-earmark-binary"/> ใบส่งสินค้า
                                        </Link>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row">
                            <div className="col-12">
                                <button onClick={handleClose} type="button" className="btn btn-secondary col-12" data-bs-dismiss="modal">ยกเลิก</button>
                            </div>
                        </div>
                    </div>
                {/* </Modal.Footer> */}
            </Modal>

        </>
    );
};

export default ExportOldData;