import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';



function SweetAlert(status, title, message) {
    const MySwal = withReactContent(Swal);


    // useEffect(() => {
        MySwal.fire({
            title: title,
            text: message,
            icon: status
        })
    // }, [])
    
}

export default SweetAlert