import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';


export default function PrintModal({Price, BillReference, AllItemBag, BillData}) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [DisableButton, setDisableButton] = useState(false);
    const [DisableCODConfirmButton, setDisableCODConfirmButton] = useState(false);
    const navigate = useNavigate();
    const [PurchaseStatus, setPurchaseStatus] = useState(false);
    const [PurchaseHistory, setPurchaseHistory] = useState([]);
    const [PaymentHistory, setPaymentHistory] = useState({});
    const [Admin, setAdmin] = useState("");
    const [clicked, setClicked] = useState(false);
    const [UserProcessed, setUserProcessed] = useState([]);
    const [AllUserGetByGenId, setAllUserGetByGenId] = useState([]);
    const [Loading, setLoading] = useState(false);

    async function SubmitPurchase(PaymentType){
        RequestAdmin();
        setDisableButton(true);
        if(PaymentType == "COD"){
            PaymentType = "COD";
        } else if(PaymentType == "INSTORE"){
            PaymentType = "INSTORE";
        } else {
            PaymentType = "INSTORE";
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/SubmitPurchase/${BillReference}`, 
            {
                PaymentType:PaymentType, 
                Genid: Admin, 
                Price: Price}, 
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });
            if(res.data.Status == true){
                increment();
                setDisableButton(false);
            } else{
                setDisableButton(false);
            }
            // console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
          setDisableButton(false);
        }
      }

      async function UpdateShippingStatus(StatusName){
        RequestAdmin();
        setDisableButton(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/BillingShippingStatus/${BillReference}/${StatusName}`, 
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                    },
                });
            if(res.status == 200){
                if(res.data.Status == true){
                    increment();
                    setDisableButton(false);
                } else{
                    setDisableButton(false);
                }
            }
            // console.log(res);
        } catch (err) {
          console.error('Error fetching bill history', err);
          setDisableButton(false);
        }
      }


      async function SubmitPaymentForCODMethod(){
        
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/InsertSubmitPayment/${BillReference}`, {
                headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                },
            });
            if(res.data.Status == true){
                increment();
                setDisableCODConfirmButton(false);
            } else{
                setDisableCODConfirmButton(false);
            }
                // console.log(res);
            } catch (err) {
                console.error('Error fetching bill history', err);
                setDisableCODConfirmButton(false);
            }
      }

      const RequestAdmin = async () => {
        const token = localStorage.getItem('token');
        if(token != null){
            await axios({
                url: `${process.env.REACT_APP_API_SERVER}/authen`,
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer "+ token
                },
            })
            .then((res) => {
                //   console.log(res.data.decoded.user_genid);
                setAdmin(res.data.decoded.user_genid);
            })
            .catch((err) => {
                console.error('Error checking session', err);
            })
        }
      }

      async function CheckPurchase(){
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/CheckPurchase/${BillReference}`, {
                headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                },
            });
                console.log(res);
                if(res.data.Status == true){
                    setPurchaseStatus(res.data.Status);
                    setPurchaseHistory(res.data.CheckPurchaseBillResult);
                    res.data.CheckPurchaseBillResult.map(list => {
                        setUserProcessed(oldArray => [...oldArray, list.purchase_genid]);
                    });
                    setLoading(false);
                }
            } catch (err) {
            console.error('Error fetching bill history', err);
            }
      }

      async function CheckPayment(){
        setLoading(true);
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/CheckPayment/${BillReference}`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
            console.log(res.data);
            if(res.data.Status == true){
                setPaymentHistory(res.data.CheckPaymentResult);
            }
        } catch (err) {
          console.error('Error fetching bill history', err);
        }
      }
      

      const RequestAdminByGenId = async () => {
          await axios.post(`${process.env.REACT_APP_API_SERVER}/getUserInformationByGenId`,
            {
                Genid: UserProcessed
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json"
                },
            })
            .then((res) => {
                // console.log(res.data);
                setAllUserGetByGenId(res.data.user);
            })
            .catch((err) => {
                console.error('Error checking session', err);
            })
      }


      useEffect(() => {
        RequestAdmin();
        CheckPurchase();
        CheckPayment();
        RequestAdminByGenId();
        if(Loading == true){
            setLoading(false);
        }
        // setClicked(false);
      }, [])

      const handleClick = () => {
        setClicked(true);
      }

      const increment = useCallback(() => {
        RequestAdmin();
        CheckPurchase();
        CheckPayment();
        RequestAdminByGenId();
        console.log(AllUserGetByGenId.length);
        console.log("PurchaseHistory "+Object.entries(PaymentHistory).length);
        // memoizedValue();
      }, [CheckPurchase, PurchaseHistory, AllUserGetByGenId, PaymentHistory]);

      

    const UserPurchaseHistoryComponent = ({ PurchaseHistory, AllUserGetByGenId }) => {
        
        if(PurchaseHistory.length > 0 && AllUserGetByGenId.length > 0){
            return (
                    <div className="p-3">
                        <div className='card card-body'>
                            <div className="row">
                                <p className="text-start">
                                    ทำรายการแล้ว {PurchaseHistory.length} ครั้งโดย
                                </p>
                                <ul className="list-group list-group-flush">
                                    {
                                        AllUserGetByGenId.map((list, index) => (
                                            <li key={index} className="list-group-item">
                                                {list.member_firstname} {list.member_lastname}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
            );
        }
    }

    
      

    return (
        <>
            {
                BillData.customer_bill_file == 'write' ? (
                    <button type="button" onClick={handleShow} className="btn btn-warning col-12">
                        <i className="bi bi-printer" /> จบการทำรายการ
                    </button>
                ) : BillData.customer_bill_file == 'onplatform' && BillData.customer_invoice_image == null ? (
                    <button type="button" onClick={handleShow} className="btn btn-warning col-12">
                        <i className="bi bi-printer" /> จบการทำรายการ
                    </button>
                ) : (
                    <button type="button" disabled={true} className="btn btn-warning col-12">
                        <i className="bi bi-printer" /> จบการทำรายการ
                    </button>
                )
            }
            <Modal centered show={show} onShow={increment} onClick={increment} onHide={handleClose} size='lg'>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">พิมพ์รายการขาย</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <div placeholder="ราคา" className="text-danger form-control price-income">
                                    {
                                        Price.Discount > 0 ? (
                                            <>-{parseFloat(Price.Discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                                        ) : (
                                            <>-{parseFloat(Price.Discount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                                        ) 
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <div placeholder="ราคา" className="text-success form-control price-income">
                                    {
                                        Price.Discount > 0 ? (
                                            <>{parseFloat(Price.IncludedDiscount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                                        ) : (
                                            <>{parseFloat(Price.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                                        ) 
                                    }
                                </div>
                                    {/* <input hidden className="text-success form-control price-income" id="totalpriceprint2" /> */}
                            </div>
                            {/* <div className="col-12">
                                <input type="number"  disabled={true} placeholder="รับมา" className="form-control price-income" id="income" />
                            </div>
                            <div className="col-12">
                                <div placeholder="ทอน" className="form-control price-income" id="amountprice">฿0.00</div>
                            </div> */}
                        </div>
                    </div>
                </Modal.Body>
                <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                    <div className="row">
                        {
                            (BillData.st_status == "process") && (AllItemBag.length > 0) && (PurchaseHistory.length < 2) ? (
                                <>
                                    <label className='mb-2'>
                                        เลือกวิธีปิดการขาย <div style={{display: 'inline'}} className='text-danger'>(โปรดตรวจสอบให้แน่ใจก่อนยืนยันการทำรายการ)</div>
                                    </label>
                                    <div className="col-6 mb-2">
                                        <Button className="btn btn-warning col-12" disabled={DisableButton} onClick={() => SubmitPurchase("INSTORE")}>
                                            <i className="bi bi-wallet"></i> ชำระเงินหน้าร้าน (เท่านั้น)
                                        </Button>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <Button className="btn btn-warning col-12" disabled={DisableButton} onClick={() => SubmitPurchase("COD")}>
                                            <i className="bi bi-cash-coin"></i> ชำระเงินปลายทาง
                                        </Button>
                                    </div>
                                </>
                            ) : (BillData.st_status == "pause") && (PurchaseHistory.length < 2) ? (
                                <>
                                    <div className="col-12 mb-2">
                                        <Button className="btn btn-danger col-12">
                                            โปรดกู้คืนรายการก่อนทำรายการต่อ
                                        </Button>
                                    </div>
                                </>
                            ) : (BillData.st_status == "pause") && (PurchaseHistory.length == 2) ? (
                                <>
                                    <div className="col-12 mb-2">
                                        <Button className="btn btn-danger col-12">
                                            ยกเลิกการขายนี้เรียบร้อยแล้ว
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <label className='mb-2'>
                                        เลือกวิธีปิดการขาย <div style={{display: 'inline'}} className='text-danger'>(โปรดตรวจสอบให้แน่ใจก่อนยืนยันการทำรายการ)</div>
                                    </label>
                                    <div className="col-6 mb-2">
                                        <Button className="btn btn-warning col-12" disabled={true}>
                                            <i className="bi bi-wallet"></i> ชำระเงินหน้าร้าน (เท่านั้น)
                                        </Button>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <Button className="btn btn-warning col-12" disabled={true}>
                                            <i className="bi bi-cash-coin"></i> ชำระเงินปลายทาง
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                            {
                                (BillData.st_status == "success") && (AllItemBag.length > 0) && (PurchaseHistory.length > 0) ? (
                                    <>
                                        <label className='mb-2'>รูปแบบใบเสร็จ</label>
                                        <div className="col">
                                            <Link to={`/PrintReceipt/Waybill/${BillReference}`} target="_blank" className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <Link to={`/PrintReceipt/WaybillAndVatIncluded/${BillReference}`} target="_blank" className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า + ภาษี
                                            </Link>
                                        </div>
                                        {
                                            PurchaseHistory[PurchaseHistory.length - 1].purchase_type == "INSTORE" || Object.entries(PaymentHistory).length > 0 ? (
                                                <>
                                                    <div className="col-6 mt-3">
                                                        <Link to={`/PrintReceipt/Receipt/${BillReference}`} target="_blank" className="btn btn-warning col-12">
                                                            <i className="bi bi-file-earmark-binary" /> ใบเสร็จรับเงินใหญ่
                                                        </Link>
                                                    </div>
                                                    <div className="col-6 mt-3">
                                                        <Link to={`/PrintReceipt/TaxInvoice/${BillReference}`} target="_blank" className="btn btn-warning col-12">
                                                            <i className="bi bi-file-earmark-binary" /> ใบกำกับภาษี
                                                        </Link>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <Link to={`/PrintReceipt/SmallReceipt/${BillReference}`} target="_blank" className="btn btn-warning col-12">
                                                            <i className="bi bi-receipt-cutoff" /> ใบเสร็จรับเงินขนาดย่อ
                                                        </Link>
                                                    </div>
                                                </>
                                            ) : PurchaseHistory[PurchaseHistory.length - 1].purchase_type == "COD" && Object.entries(PaymentHistory).length == 0 ? (
                                                <>
                                                    <div className="col-12 mt-3">
                                                        <Button className="btn btn-warning col-12" disabled={DisableButton} onClick={() => SubmitPaymentForCODMethod()}>
                                                            <i className="bi bi-check" /> ชำระเงินปลายทางแล้ว (คลิกที่นี่)
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : null
                                        }

                                        <label className='mt-2 mb-2'>สถานะการจัดส่ง</label>
                                        {
                                            BillData.bill_shipping_status == 'process' ? (
                                                <>
                                                    <div className="col-6">
                                                        <Button className="btn btn-warning col-12" disabled={DisableButton} onClick={() => {UpdateShippingStatus('StorePickUp')}}>
                                                            <i className="bi bi-check" /> ลูกค้ารับสินค้าด้วยตัวเอง
                                                        </Button>
                                                    </div>
                                                    <div className="col-6">
                                                        <Button className="btn btn-warning col-12" disabled={DisableButton} onClick={() => {UpdateShippingStatus('ShippedSuccess')}}>
                                                            <i className="bi bi-check" /> จัดส่งแล้ว
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : BillData.bill_shipping_status == 'ShippedSuccess' ? (
                                                    <>
                                                        <div className="col-12">
                                                            <Button className="btn btn-warning col-12">
                                                                <i className="bi bi-truck"/> จัดส่งแล้ว
                                                            </Button>
                                                        </div>
                                                    </>
                                            ) : BillData.bill_shipping_status == 'StorePickUp' ? (
                                                <>
                                                    <div className="col-12">
                                                        <Button className="btn btn-warning col-12">
                                                            <i className="bi bi-box-seam"/> ลูกค้ารับสินค้าด้วยตัวเอง
                                                        </Button>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                        

                                        
                                    </>
                                ) : (BillData.st_status == "pause") ? (
                                    <></>
                                ) : (Loading == true) ? (
                                    <>
                                        <label className='mb-2'>รูปแบบใบเสร็จ</label>
                                        <div className="col-6">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า
                                            </Button>
                                        </div>
                                        <div className="col-6">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า + ภาษี
                                            </Button>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-receipt-cutoff" /> ใบเสร็จรับเงินใหญ่
                                            </Button>
                                        </div>
                                        <div className="col-6 mt-3">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบกำกับภาษี
                                            </Button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-receipt-cutoff" /> ใบเสร็จรับเงินขนาดย่อ
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <label className='mb-2'>รูปแบบใบเสร็จ</label>
                                        <div className="col-6">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า
                                            </Button>
                                        </div>
                                        <div className="col-6">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบส่งสินค้า + ภาษี
                                            </Button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-file-earmark-binary" /> ใบกำกับภาษี
                                            </Button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <Button disabled={true} className="btn btn-warning col-12">
                                                <i className="bi bi-receipt-cutoff" /> ใบเสร็จรับเงินขนาดย่อ
                                            </Button>
                                        </div>
                                    </>
                                ) 
                            }
                            {
                                (PurchaseHistory.length == 1) ? (
                                    <>
                                        <div className="col-12 mt-3">
                                            <Button variant="outline-danger" className="col-12 toast-blink">
                                                เหลือโอกาสแก้ไขใบเสร็จ 1 ครั้ง
                                            </Button>
                                        </div>
                                    </>
                                ) : null
                            }
                            
                            <UserPurchaseHistoryComponent PurchaseHistory={PurchaseHistory} AllUserGetByGenId={AllUserGetByGenId}/>
                                
                    </div>
                </div>
            </Modal>
        </>
    )
}
