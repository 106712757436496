import React, { useState, useEffect, useRef, useCallback } from 'react';
import NavBar from './NavBar';
import axios from 'axios';
import Authen from '../Validation/Authen';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ShortText from '../Function/Text';
import { Link, useNavigate } from 'react-router-dom';
import NewBillModal from './Modal/NewBillModal';
import { debounce } from 'lodash';
import ExportOldData from './Modal/ExportOldData'
import ShippingStates from '../Function/ShippingStates'


export default function PosHome() {
  Authen();
  const [typeOfSearching, setTypeOfSearching] = useState("text");
  const [allDateBillHistories, setAllDateBillHistories] = useState({});
  const [billSearchByDate, setBillSearchByDate] = useState(null);
  const [billSearchKeywords, setBillSearchKeywords] = useState("");
  const [Loading, setLoading] = useState('');
  const navigate = useNavigate();


  const showHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/AllBillHistory`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      setAllDateBillHistories(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching bill history', err);
    }
  };

  // const handleSearch = async () => {
  //   try {
  //     const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/AllBillHistory/SortSearch`, {
  //       BillSearchByDate: billSearchByDate,
  //       BillSearchKeywords: billSearchKeywords,
  //     }, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setAllDateBillHistories(res.data);
  //     console.log(res.data);
  //   } catch (err) {
  //     console.error('Error searching bill history', err);
  //   }
  // };

  const handleSearch = useCallback(debounce(async (billSearchByDate, billSearchKeywords) => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/AllBillHistory/SortSearch`, {
        BillSearchByDate: billSearchByDate,
        BillSearchKeywords: billSearchKeywords,
      }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      setAllDateBillHistories(res.data);
      setLoading(false);
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  }, 500), [billSearchByDate, billSearchKeywords]);

  useEffect(() => {
    showHistory();
  }, []);

  // const SearchCallBack = useCallback(() => {
    // handleSearch();
  // }, [billSearchByDate, billSearchKeywords]);

  // useEffect(() => {
  //   handleSearch();
  // }, [billSearchByDate, billSearchKeywords]);


  const handleSearchTypeChange = (e) => {
    setTypeOfSearching(e.target.value);
    setBillSearchByDate(null);
    setBillSearchKeywords("");
  };

  const handleInputChange = (e) => {
    setBillSearchByDate(null);
    setBillSearchKeywords(e.target.value);
    // SearchCallBack();
    console.log(e.target.value);
  };

  const handleDateChange = (date) => {
    setBillSearchKeywords("");
    setBillSearchByDate(date);
    // SearchCallBack();
    console.log(date);
  };

  const renderSearchInput = () => {
    if (typeOfSearching === "date") {
      return (
        <DatePicker selected={billSearchByDate} onChange={handleDateChange} onSelect={(date) => handleSearch(date, "")} dateFormat="yyyy-MM-dd" placeholderText="Select a date" wrapperClassName='col-12' className="form-control form-control-lg"/>
      );
    } else if (typeOfSearching === "text") {
        return (
            <div className="row">
              <div className="col">
                <input type="text" value={billSearchKeywords} onChange={(e) => {handleInputChange(e); handleSearch(null, e.target.value);}} autoComplete="off" className="form-control form-control-lg col-12" placeholder="ค้นหารายการในอดีต | ป้อนชื่อลูกค้า หมายเลขโทรศัพท์หรือที่อยู่"/>
              </div>
              {
                !billSearchKeywords.search('spos-') && (
                  <div className="col-2">
                    <Link to={`/sPos/${billSearchKeywords}`} className='btn btn-success btn-lg col-12'>ไป</Link>
                  </div>
                )
              }
            </div>
        );
    } else if(typeOfSearching == 'month'){
      return(
        <Last12Months/>
      );
    }
  };


  const DateChangeToThaiFromat = (date) => {
    const TodayDateResult = new Date(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")).toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    })
    return TodayDateResult
  }

  const getLast12Months = () => {
    const months = [[], []];
    const today = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const month = date.toLocaleString('default', { month: 'long' });
      const monthToThai = date.toLocaleString('th-TH', { month: 'long' });
      const year = date.getFullYear();
      months[0].push(`${month} ${year}`);
      months[1].push(`${monthToThai} ${year}`);
    }
    return months;
  };
  
  const Last12Months = () => {
    const months = getLast12Months();
  
    return (
      <>
        <select className='form-select form-select-lg' name="month" id="">
          {months[0].map((month, index) => (
            <option value={month}>{months[1][index]}</option>
          ))}
        </select>
      </>
    );
  };


  


  const renderHistoryBill = () => {
    const GoPos = (data) => {
      navigate("/sPos/"+data);
    }
    if(Loading){
      return (
        <>
          {/* <h3 className="text-center mt-2">กรุณารอสักครู่ ระบบกำลังโหลดข้อมูล...</h3> */}
        </>
      )
    }
    return Object.keys(allDateBillHistories).map((date) => (
      <div key={date} className='row'>
        <div className="col-12">
          <h4 className="card card-body text-center mt-3 text-secondary">
            {
              DateChangeToThaiFromat(date)
            }
          </h4>
        </div>
        {allDateBillHistories[date].map((bill, index) => (
          <div className='col-md-3' key={bill.bill_id}>
            <div className="card card-colorsmk-blink cursor-pointer" onClick={() => GoPos(bill.bill_reference)}>
              <div className="card-body">
                <div className="row">
                  <div className="col-9">
                    <h6 className="card-title" align="left">
                      {ShortText(bill.bill_name, 35)}
                    </h6>
                  </div>
                  <div className="col-3" align="right">
                    <span className='badge rounded-pill text-bg-primary'>
                      {`#${bill.bill_id}`}
                    </span>
                  </div>
                  <div className="col-3 mt-3">
                    <button className="btn btn-light col-12">
                      {moment(bill.bill_time, 'HH:mm:ss').format('H:mm')}
                    </button>
                  </div>
                  <div className="col-9 mt-3">
                    <div className="row">
                      {
                        bill.customer_payment_method == "payment-success" && (bill.st_status == "success" || bill.st_status == "pause") && bill.pmh_status != 'success' ? (
                          <>
                            <div className="col-4">
                              <button className="btn btn-warning col-12">
                                <i className="bi bi-cash-coin" /> 
                                {/* ชำระหน้าร้าน */}
                              </button>
                            </div>
                            {
                              bill.bill_shipping_status == 'process' ? (
                                <div className="col-4">
                                  <button className="btn btn-warning col-12">
                                    <i className="bi bi-hourglass-split" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'StorePickUp' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-box-seam" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'ShippedSuccess' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-truck" /> 
                                  </button>
                                </div>
                              ) : null
                            }
                          </>
                        ) : (bill.customer_payment_method == "payment-debts") && (bill.st_status == "success" || bill.st_status == "pause") && bill.pmh_status != 'success' ? (
                          <>
                            <div className="col-4">
                              <button className="btn btn-dark col-12">
                                <i className="bi bi-house-exclamation" /> 
                                {/* เก็บเงินปลายทาง */}
                              </button>
                            </div>
                            {
                              bill.bill_shipping_status == 'process' ? (
                                <div className="col-4">
                                  <button className="btn btn-warning col-12">
                                    <i className="bi bi-hourglass-split" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'StorePickUp' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-box-seam" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'ShippedSuccess' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-truck" /> 
                                  </button>
                                </div>
                              ) : null
                            }
                          </>
                          
                        ) : (bill.customer_payment_method == "payment-debts") && (bill.st_status == "success") && bill.pmh_status == 'success' ? (
                          <>
                            <div className="col-4">
                              <button className="btn btn-success col-12">
                                <i className="bi bi-house-check-fill" /> 
                                {/* ชำระเงินแล้ว */}
                              </button>
                            </div>
                            {
                              bill.bill_shipping_status == 'process' ? (
                                <div className="col-4">
                                  <button className="btn btn-warning col-12">
                                    <i className="bi bi-hourglass-split" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'StorePickUp' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-box-seam" /> 
                                  </button>
                                </div>
                              ) : bill.bill_shipping_status == 'ShippedSuccess' ? (
                                <div className="col-4">
                                  <button className="btn btn-success col-12">
                                    <i className="bi bi-truck" /> 
                                  </button>
                                </div>
                              ) : null
                            }
                          </>
                        ) : null
                      }
                      { 
                        bill.st_status == "pause" && (bill.customer_payment_method == null) ? (
                          <div className="col-12">
                            <button className="btn btn-danger col-12">
                              <i className="bi bi-bag-x" /> 
                              {/* ยกเลิก */}
                            </button>
                          </div>
                        ) : bill.st_status == "pause" && (bill.customer_payment_method != null) ? (
                          <div className="col-4">
                            <button className="btn btn-danger col-12">
                              <i className="bi bi-bag-x" /> 
                              {/* ยกเลิก */}
                            </button>
                          </div>
                        ) : bill.st_status == "success" ? (
                          <div className="col-4">
                            <button className="btn btn-success col-12">
                              <i className="bi bi-bag-check" /> 
                              {/* สำเร็จ */}
                            </button>
                          </div>
                        ) : (bill.st_status == "process") ? (
                          <div className="col-12">
                            <button className="btn btn-info col-12">
                              <i className="bi bi-bag-plus" /> 
                              {/* กำลังทำรายการ */}
                            </button>
                          </div>
                        ) : null
                      
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const handleShowHistory = () => {
    if(typeOfSearching == 'text'){
      showHistory();
    }
  }

  return (
    <>
      <NavBar />
      <div className='container-fluid' onClick={handleShowHistory}>
        <div className='row mt-3'>
          <div className='col-md-2'>
            <div className="d-flex flex-column flex-shrink-0 pb-1 ps-1 pe-1">
              <h1 className="smktitle mb-0">sPos</h1>
              <hr className='mt-0' />
              <ul className="nav nav-pills flex-column mb-auto">
                <li className="nav-item">
                  <div className="nav-link-sidebar active-sidebar" aria-current="page">
                    <NewBillModal />
                  </div>
                </li>
                <li>
                  <Link className='nav-link-sidebar link-body-emphasis' target='_blank' to={`https://smkconnectapi.online/pos/index.php?login_from_url=${localStorage.getItem('usersecretid')}`}>
                    <i className="bi bi-door-open-fill"/> SMKCONNECTPortal+
                  </Link>
                </li>
                <li>
                  <ExportOldData/>
                </li>
                <li>
                  <Link className='nav-link-sidebar link-body-emphasis' to={`/SalesHistory`}>
                    <i className="bi bi-file-earmark-spreadsheet-fill" /> รายงานการขาย
                  </Link>
                </li>
              </ul>
              <hr />
              <ShippingStates/>
            </div>

          </div>
          <div className='col-md-10'>
            <div className='row'>
              <div className='col-8'>
                {renderSearchInput()}
              </div>
              <div className='col-4'>
                <select defaultValue={"text"} onChange={handleSearchTypeChange} id="searchhistory_month" className="col-12 form-select form-select-lg">
                  <option value="text">ชื่อผู้ซื้อหรือหมายเลขโทรศัพท์</option>
                  <option value="date">วันที่ - เดือน - ปี</option>
                  <option value="month">เดือน - ปี</option>
                </select>
              </div>
            </div>
            {renderHistoryBill()}
          </div>
        </div>
      </div>


    </>
  );
}
