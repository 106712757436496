import { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SearchContactList from '../../Function/ContactList';

const NewBillModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        GetStatusQuantity();
    };
    const navigate = useNavigate();
    const [StatusQuantity, setStatusQuantity] = useState(0);
    const [ContactData, setContactData] = useState({});
    const exceptThisSymbols = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const [AlertWarningCharacter, setAlertWarningCharacter] = useState(false);
    const [Errors, setErrors] = useState({});

    const [Values, setValues] = useState({
        customer_name: "Customer",
        customer_contact: "0000000000",
        customer_payment: "cash",
        customer_taxid: "",
        customer_address: "ไม่มีที่อยู่",
        customer_bill_type: 'onplatform'
    })

    const GetStatusQuantity = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/GetStatusQuantity/Number`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
          if(res.status == 200){
            if(res.data.Status == true){
              console.log(res.data.Data);
              setStatusQuantity(res.data.Data);
            }
          }
        } catch (err) {
          console.error('Error searching bill history', err);
        }
    };

    const handleInput = (event) => { 
        setValues(prev => ({...prev, [event.target.name]: event.target.value}));
        if(event.target.value.length > 14){
            setAlertWarningCharacter(true);
        } else {
            setAlertWarningCharacter(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/CreateNewBill`, Values, 
            {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                },
            });
            console.log(res);
            if(res.status == 200){
                navigate("/sPos/"+res.data.BillReferenceCreated);
            }
        } catch (err) {
            console.error('Error searching bill history', err);
            if(err.response.status == 400){
                setErrors(err.response.data.errors);
            }
        }
    };

    const handleSetContactData = (Data) => {
        const {contact_name, contact_phone, contact_taxid, contact_address} = Data;
        // setValues(prev => ({...prev, ['customer_name']: contact_name}))
        setValues({
            customer_name: contact_name,
            customer_contact: contact_phone,
            customer_payment: "cash",
            customer_taxid: contact_taxid || '',
            customer_address: contact_address || 'ไม่มีที่อยู่',
            customer_bill_type: 'onplatform'
        });
        console.log(contact_phone);
    };

    useEffect(() => {
        
    }, [Values])

    const increment = useCallback(() => {
        
      }, [Values])
    
    

    return (
        <>
            <div onClick={handleShow}>
                <i className="bi bi-file-earmark-plus-fill" /> สร้างใบเสร็จ
            </div>
            {/* <button className='btn btn-secondary btn-lg col-12 mb-3' onClick={handleShow}>
              <i className="bi bi-file-earmark-plus-fill" /> สร้างใบเสร็จ
            </button> */}
            <Modal centered show={show} onClick={increment} onHide={handleClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">สร้างใบเสร็จ</h5>
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-4">
                            <label>รายชื่อลูกค้าที่บันทึก</label>
                            <SearchContactList ContactData={handleSetContactData} />
                        </div>
                        <div className='col-md-8'>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>ชื่อลูกค้า 
                                        {
                                            !AlertWarningCharacter ? <div className="text-primary d-inline"> ( * กรุณากรอกชื่อลูกค้าให้ถูกต้อง ลดการใช้คำย่อและไม่กรอกที่อยู่ในช่องนี้ ) </div> : <div className="text-danger d-inline"> ( !!! กรณีกรอกข้อมูลไม่เพียงพอกรุณากรอกในช่องที่อยู่ !!! ) </div>
                                        }
                                    </label>
                                    <input onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} onChange={handleInput} type="text" autoComplete="off" maxLength={15} name="customer_name" className="form-control" Value={Values.customer_name} />
                                    {Errors.customer_name && <div className="form-text text-danger">{Errors.customer_name}</div>}
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '1rem'}}>
                                <div className="col-md-6">
                                    <label>หมายเลขโทรศัพท์ลูกค้า</label>
                                    <input onChange={handleInput} type="tel" autoComplete="off" name="customer_contact" className="form-control" placeholder="หากไม่มีโปรดเว้นว่าง" Value={Values.customer_contact} />
                                    {Errors.customer_contact && <div className="form-text text-danger">{Errors.customer_contact}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label>ประเภทการชำระเงิน</label>
                                    <select onChange={handleInput} name="customer_payment" className="form-select dropdown" defaultValue={Values.customer_payment}>
                                        <option value="cash">เงินสด</option>
                                        <option value="banktransfer">สแกนชำระ / โอนพร้อมเพย์</option>
                                    </select>
                                    {Errors.customer_payment && <div className="form-text text-danger">{Errors.customer_payment}</div>}
                                </div>
                            </div>
                            <div className="row" style={{marginTop: '1rem'}}>
                                <div className="col-md-6">
                                    <label>เลขประจำตัวผู้เสียภาษี</label>
                                    <input onChange={handleInput} type="text" autoComplete="off" name="customer_taxid" className="form-control" placeholder="หากไม่มีโปรดเว้นว่าง" Value={Values.customer_taxid}/>
                                    {Errors.customer_taxid && <div className="form-text text-danger">{Errors.customer_taxid}</div>}
                                </div>
                                <div className="col-md-6">
                                    <label>ประเภทใบเสร็จ</label>
                                    <select onChange={handleInput} name="customer_bill_type" className="form-select dropdown" Value={Values.customer_bill_type}>
                                        <option value="onplatform">SMKCONNECTPLUS+</option>
                                        <option value="write">เขียนมือ</option>
                                    </select>
                                    {Errors.customer_bill_type && <div className="form-text text-danger">{Errors.customer_bill_type}</div>}
                                </div>
                            </div>
                            <div className="col-md-12" style={{marginTop: '1rem'}}>
                                <label>ที่อยู่จัดส่งลูกค้า</label>
                                <textarea onChange={handleInput} name="customer_address" className="form-control" placeholder="ถ้าไม่มีโปรดกรอก ไม่มีที่อยู่" Value={Values.customer_address} />
                                {Errors.customer_address && <div className="form-text text-danger">{Errors.customer_address}</div>}
                            </div>
                        </div>
                    </div>
                    

                </Modal.Body>
                {/* <Modal.Footer> */}
                    <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                        <div className="row">
                            <div className="col-6">
                                <button onClick={handleClose} type="button" className="btn btn-outline-secondary col-12" data-bs-dismiss="modal">ยกเลิก</button>
                            </div>
                            {
                                StatusQuantity >= 5 ? (
                                    <div className="col-6">
                                        <div onClick={handleClose} className="btn btn-danger col-12">กรุณาอัพเดทสถานะการขนส่งก่อนสร้างใบเสร็จ</div>
                                    </div>
                                ) : (
                                    <div className="col-6">
                                        <button onClick={handleClose} type="submit" data-bs-dismiss="modal" name="create" className="btn btn-secondary col-12">สร้างใบเสร็จ</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                {/* </Modal.Footer> */}
                </form>
            </Modal>

        </>
    );
};

export default NewBillModal;