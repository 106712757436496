import {React, useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function NavBar() {
    const [UserData, setUserData] = useState('');
    useEffect(() => {
        const checkAuthen = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                const response = await axios({
                    url: `${process.env.REACT_APP_API_SERVER}/getUserInformation`,
                    method: "POST",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token
                    },
                });
                    setUserData(response.data.user);
                } catch (err) {
                    console.error('Error checking session', err);
                }
            } 
        };

    checkAuthen();
    }, []);

    function handleHome(){
        window.location = "/Home";
    }

    const CurrentLocation = useLocation().pathname;



    return (
        <div>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <img className='logo-smk cursor-pointer' src={process.env.PUBLIC_URL + "/assets/smkheader.png"} style={{height: '50px'}} onClick={handleHome}/>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/Home' ? 'active' : ''}`} aria-current="page" href="/Home">หน้าแรก</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/BarcodePrinting' ? 'active' : ''}`} aria-current="page" href="/BarcodePrinting">พิมพ์บาร์โค้ด</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/ContactService' ? 'active' : ''}`} aria-current="page" href="/ContactService">ประวัติผู้ติดต่อ</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/PosProduct' ? 'active' : ''}`} aria-current="page" href="/PosProduct">ประวัติสินค้า</a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link underline" aria-current="page" href="/sWarehouse">sWarehouse</a>
                            </li> */}
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/sPosHome' || CurrentLocation == '/SalesHistory' || !CurrentLocation.search('/sPos/') ? 'active' : ''}`} aria-current="page" href="/sPosHome">โปรแกรมขายหน้าร้าน</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/sQuotationHome' || !CurrentLocation.search('/sQuotation/') ? 'active' : ''}`} aria-current="page" href="/sQuotationHome">ใบเสนอราคา</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link underline ${CurrentLocation == '/sBillingInvoiceHome' || !CurrentLocation.search('/sBillingInvoice/') ? 'active' : ''}`} aria-current="page" href="/sBillingInvoiceHome">ใบแจ้งหนี้</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link underline" href="/Logout">ออกจากระบบ</a>
                            </li>
                        </ul>
                        <div className="d-flex ms-auto">
                            <ul className="navbar-nav">
                                <div className="nav-link">
                                    <i className="bi bi-person-circle"></i> {UserData.member_firstname+' '+UserData.member_lastname}
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

        </div>
    );
    }

export default NavBar;