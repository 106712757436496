import React, { useState } from 'react'
import axios from 'axios'
import LoginValidation from '../Validation/LoginValidation'
import {Link, Navigate, useNavigate} from 'react-router-dom'

export default function Login() {
    const [Values, setValues] = useState({
        username_or_email: "",
        password: "",
    })

    const handleInput = (event) => {
        setValues(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    const navigate = useNavigate();
    const [Errors, setErrors] = useState({})
    const [AlertHeaderError, setAlertHeaderError] = useState({})
    const [AlertHeaderSuccess, setAlertHeaderSuccess] = useState("")
    axios.default.withCredentials = true;
    const handleSubmit = (event) =>{
        event.preventDefault();
        setErrors(LoginValidation(Values));
        console.log(Errors);
        // console.log(Values);

        // if(Errors.username_or_email == "" && Errors.password == ""){
        if(JSON.stringify(Errors) == "{}"){
          console.log(Values);
          axios({
            url: `${process.env.REACT_APP_API_SERVER}/login`,
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            data: Values,
          })
            // Handle the response from backend here
            .then((res) => {
              console.log(res);
              setAlertHeaderError({errors: [""]})
              setAlertHeaderSuccess(res.data.message);
              if(res.status == 200){
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('expire', res.data.decoded.exp);
                setTimeout(() => {
                    window.location = "/Home";
                }, 500);
              } else{
                    localStorage.removeItem('token');
              }
            })
 
            // Catch errors if any
            .catch((err) => {
              console.log(err);
              console.log(err.response.status);
              if(err.response.status == 400){
                setAlertHeaderSuccess("");
                setAlertHeaderError(err.response.data.errors);
              }
              localStorage.removeItem('token');
            })
        }

    }


    const [showPasswords, setShowPasswords] = useState(false);
    const handleCheckboxChange = () => {
      setShowPasswords(!showPasswords);
    };
  

  return (
  <>
    <div className="bg-res2 vh-100" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundAttachment: 'fixed'
      }} >
      <div className='row'>
        <div className='col-md-4 mt-5'></div>
        <div className='col-md-4 d-flex justify-content-center align-items-center mt-5'>
          <img className='logo-smk cursor-pointer' onClick={() => navigate('/Register')} height='100' src={process.env.PUBLIC_URL + "/assets/smkheader.png"} />
        </div>
        <div className='col-md-4 mt-5'></div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-4'></div>
          <div className={`col-md-4 frame`}>
          <div className='mb-3'>
            <h4 className='text-center'>เข้าสู่ระบบ</h4>
            {AlertHeaderError && <h6 className="text-danger text-center">{AlertHeaderError[0]}</h6>}
            {AlertHeaderSuccess && <h6 className="text-success text-center">{AlertHeaderSuccess}</h6>}
          </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                {/* <label className="form-label">Username</label> */}
                <input type="text" onChange={handleInput} name="username_or_email" placeholder="ชื่อผู้ใช้" className="form-control"/>
                {Errors.username_or_email && <div className="form-text text-danger">{Errors.username_or_email}</div>}
              </div>
              <div className="mb-3">
                {/* <label htmlFor="exampleInputPassword1" className="form-label">Password</label> */}
                <input type={showPasswords ? 'text' : 'password'} onChange={handleInput} name="password" placeholder="รหัสผ่าน" className="form-control" />
                {Errors.password && <div className="form-text text-danger">{Errors.password}</div>}
              </div>
              <div className="mb-3">
                <input type="checkbox" id="showPasswords" className="form-check-input" checked={showPasswords} onChange={handleCheckboxChange}/>
                <label className="form-check-label">แสดงรหัสผ่าน</label>
              </div>
              <div className='row mb-2'>
                <div className="col-6">
                    <input type="submit" className="btn btn-secondary col-12" value="เข้าสู่ระบบ" />
                </div>
                <div className="col-6">
                    {/* <Link to="/" type="button" className="btn btn-secondary col-12" disabled={true}>ลืมรหัสผ่าน</Link> */}
                    <button className="btn btn-secondary col-12" disabled={true}>ลืมรหัสผ่าน</button>
                </div>
              </div>
              {/* <div className='row'>
                <div className="col-12">
                    <Link to="/register" type="button" className="btn btn-secondary col-12">Register</Link>
                </div>
              </div> */}
            </form>
          </div>
          <div className='col-md-4'></div>
        </div>

        <div className="row" style={{marginTop: '6rem'}}>
          <div className="col-6" align="right">
            <img src={`${process.env.PUBLIC_URL}/assets/smkconcretelogo.png`} className="logo-smk" height={47} />
          </div>
          <div className="col-6" align="left">
            <p className="text-left text-light" style={{fontSize: 8}}>
              SMKCONNECTPLUS+ Professional Edition<br />
              a private web-application for<br />
              SOMSAKCONCRETE LIMITED PARTNERSHIP<br />
              ห้างหุ้นส่วนจำกัดสมศักดิ์คอนกรีต <i className="bi bi-r-circle" />
            </p>
          </div>
        </div>
      </div>
    

        
    </div>

    </>
  )
}
