import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';
import axios from 'axios';
import moment from 'moment';
import "moment/locale/th";
import FormatThaiDate from '../ReFormat/FormatThaiDate.js';
import SarabunRegular from '../../Sarabun/Sarabun-Regular.ttf';
import SarabunBold from '../../Sarabun/Sarabun-Bold.ttf';
// import ShortText from './Text';
import BillNumber from '../BillNumber.js';
import ConvertToThai from '../ConvertToThai.js'
import ToThaiTotal from '../ReFormat/ToThaiTotal.js';
import DateThaiFormat from '../ReFormat/DateThaiFormat.js';


  Font.register({
    family: 'Sarabun',
    fonts: [
      { src: SarabunRegular, fontStyle: 'normal', fontWeight: 'normal' },
      { src: SarabunBold, fontStyle: 'normal', fontWeight: 'bold' },
      ],
    });


  const styles = StyleSheet.create({
    body: {
      padding: 12,
      fontSize: 9,
      fontFamily: 'Sarabun',
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeader: {
      width: "15%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
    },
    tableColHeaderNumber: {
      width: "5%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
    },
    tableColHeaderList2: {
        width: "50%",
        // borderLeftWidth: 0.50,
        // borderRightWidth: 0.50,
        // borderBottomWidth: 0,
        // borderTopWidth: 0,
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 5,
    },
    tableColHeaderList: {
        // borderBottomWidth: 0.50,
        // borderTopWidth: 0.50,
        width: "50%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderAmountAndUnit: {
      width: "10%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
    },
    tableCol: {
      width: "15%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      textAlign: "center",
      padding: 5,
    },
    tableCellHeader: {
      fontSize: 9,
      fontWeight: 'bold',
    },
    tableCell: {
      fontSize: 9,
    },
    footer: {
      marginTop: 10,
      textAlign: 'right',
      fontWeight: 'bold',
    },
    totalRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 5,
    },
    tableColNumber:{
        // borderLeftWidth: 0.50,
        // borderRightWidth: 0.50,
        width: "5%",
        borderStyle: "solid",
        // borderWidth: 0.50, 
        backgroundColor: "#ffffff",
        borderColor: '#000',
        textAlign: "center",
        padding: 5,
    },
    totalCell: {
      width: "15%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      textAlign: "center",
      padding: 5,
    },
    tableList: {
      width: "15%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#000',
      textAlign: "center",
      padding: 5,
    },
    headerText: {
        fontFamily: 'Sarabun',
        fontSize: 9,
        marginBottom: 3,
    },
    headerBill: {
        fontFamily: 'Sarabun',
        fontSize: 11,
        marginBottom: 3,
    },
  });

  const List = StyleSheet.create({
    tableColHeaderNumber_Normal: {
        width: "5%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderList_Normal: {
        width: "50%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        // borderBottomWidth: 0,
        // borderTopWidth: 0,
        borderStyle: "solid",
        // borderWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 5,
    },
    tableColHeaderAmountAndUnit_Normal: {
        width: "10%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
      },
    tableCol_Normal: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#000',
        textAlign: "center",
        padding: 5,
      },
    tableColHeaderNumber_LR: {
        width: "5%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderList_LR: {
        width: "50%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 5,
    },
    tableColHeaderAmountAndUnit_LR: {
        width: "10%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableCol_LR: {
        width: "15%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderNumber_LR: {
        width: "5%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
      },
    tableColHeaderList_LR: {
        width: "50%",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 5,
    },
    tableColHeaderAmountAndUnit_LR: {
        width: "10%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableCol_LR: {
        width: "15%",
        borderStyle: "solid",
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderNumber_LR: {
      width: "5%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
    },
    tableColHeaderNumber_LR_T: {
        width: "5%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderList_LR_T: {
        width: "50%",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderStyle: "solid",
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "left",
        padding: 5,
    },
    tableColHeaderAmountAndUnit_LR_T: {
        width: "10%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        backgroundColor: "#ffffff",
        textAlign: "center",
        padding: 5,
    },
    tableCol_LR_T: {
        width: "15%",
        borderStyle: "solid",
        borderTopWidth: 0.50,
        borderLeftWidth: 0.50,
        borderRightWidth: 0.50,
        borderColor: '#000',
        textAlign: "center",
        padding: 5,
    },
    tableColHeaderNumber_LR_T: {
      width: "5%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
  },
  tableColHeaderList_LR_T: {
      width: "50%",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderStyle: "solid",
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "left",
      padding: 5,
  },
  tableColHeaderAmountAndUnit_LR_T: {
      width: "10%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
  },
  tableCol_LR_T: {
      width: "15%",
      borderStyle: "solid",
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderTopWidth: 0.50,
      borderColor: '#000',
      textAlign: "center",
      padding: 5,
  },
  tableColHeaderNumber_LR_T: {
    width: "5%",
    borderStyle: "solid",
    borderTopWidth: 0.50,
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderColor: '#000',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 5,
  },
  tableColHeaderList_LR_B: {
      width: "50%",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderStyle: "solid",
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "left",
      padding: 5,
  },
  tableColHeaderAmountAndUnit_LR_B: {
      width: "10%",
      borderStyle: "solid",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#000',
      backgroundColor: "#ffffff",
      textAlign: "center",
      padding: 5,
  },
  tableCol_LR_B: {
      width: "15%",
      borderStyle: "solid",
      borderBottomWidth: 0.50,
      borderLeftWidth: 0.50,
      borderRightWidth: 0.50,
      borderColor: '#000',
      textAlign: "center",
      padding: 5,
  },
  tableColHeaderNumber_LR_B: {
    width: "5%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#000',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 5,
  },
  tableColHeaderList_LR_B: {
    width: "50%",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderStyle: "solid",
    borderColor: '#000',
    backgroundColor: "#ffffff",
    textAlign: "left",
    padding: 5,
  },
  tableColHeaderAmountAndUnit_LR_B: {
    width: "10%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#000',
    backgroundColor: "#ffffff",
    textAlign: "center",
    padding: 5,
  },
  tableCol_LR_B: {
    width: "15%",
    borderStyle: "solid",
    borderLeftWidth: 0.50,
    borderRightWidth: 0.50,
    borderBottomWidth: 0.50,
    borderColor: '#000',
    textAlign: "center",
    padding: 5,
  },
  });

  const HeaderTable = StyleSheet.create({
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      borderCollapse: "collapse",
      textAlign: "left",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeaderName: {
      width: "18%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      padding: 5,
    },
    tableColHeaderDetail: {
      width: "18%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      paddingLeft: 5,
      paddingTop: 4
    },
    tableColHeaderInfo: {
      width: "32%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      paddingLeft: 5,
      paddingTop: 4
    },
    tableCol: {
      width: "18%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      padding: 5,
    },
    tableCellHeader: {
      fontSize: 9,
    },
    tableCell: {
      fontSize: 9,
    },
    tableCellList: {
        fontSize: 9,
        textAlign: 'left'
      },
    footer: {
      marginTop: 10,
      textAlign: 'right',
      fontWeight: 'bold',
    },
    tableColHeaderAddress:{
      width: "82%",
      borderStyle: "solid",
      borderWidth: 0.50,
      borderColor: '#ffffff',
      backgroundColor: "#ffffff",
      paddingLeft: 5,
      paddingTop: 4
    }
  });

  const LogoTable = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        borderCollapse: "collapse",
        textAlign: "left",
      },
      tableRow: {
        flexDirection: "row",
      },
      tableColHeaderLogo: {
        width: "50%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        padding: 5,
      },
      tableCol: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        padding: 5,
      },
      tableCellHeader: {
        fontSize: 9,
      },
      tableCell: {
        fontSize: 9,
      },
      footer: {
        marginTop: 10,
        textAlign: 'right',
        fontWeight: 'bold',
      },
      tableColHeaderBarcode:{
        width: "50%",
        borderStyle: "solid",
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        textAlign: 'right',
        alignItems: 'flex-end',
        marginright: 'auto',
        padding: 5

      }
  });

  const QRCodeStyle = StyleSheet.create({
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        borderCollapse: "collapse",
        textAlign: "left",
        marginTop: 10
      },
      tableRow: {
        flexDirection: "row",
      },
      tableColHeaderQRCode: {
        width: "15%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        marginLeft: 5,
      },
      tableColHeaderQRCodeDetail: {
        width: "25%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        marginLeft: 5,
      },
      tableColHeaderQRCodeDetailPadding: {
        paddingTop: 10
      },
      tableCol: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        marginLeft: 5,
      },
      tableColHeaderQRText: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 0.50,
        borderColor: '#ffffff',
        backgroundColor: "#ffffff",
        // padding: 5,
      },
      headerText: {
        fontFamily: 'Sarabun',
        fontSize: 8,
        marginBottom: 3,
      },
      SignText: {
        fontFamily: 'Sarabun',
        fontSize: 9,
        marginTop: 5,
        textAlign: 'center'
      }

    });

  function PrintTaxInvoice({QRCode, Admin, BillReference, BillData, AllItemBag, NowPrice, CountItemAmountAll}) {
    


    return (
      <Document>
        <Page  style={styles.body}>
          <View style={LogoTable.table}>
              <View style={LogoTable.tableRow}>
                  <View style={LogoTable.tableColHeaderLogo}>
                        <Image src={process.env.PUBLIC_URL + "/assets/smkconcretelogo.png"} style={{width: "40%"}}/>
                  </View>
                  <View style={LogoTable.tableColHeaderBarcode}>
                        <Image src={"https://bwipjs-api.metafloor.com/?bcid=code128&text="+BillData.bill_reference} style={{width: "90%", height: 8 }} />
                        <Text style={styles.headerText}> {BillNumber(parseInt(BillData.bill_id))} </Text>
                  </View>
              </View>
          </View>

          <View style={HeaderTable.table}>
              <View style={HeaderTable.tableRow}>
                  <View style={LogoTable.tableColHeaderLogo}>
                      <Text style={styles.headerBill}> {"ใบกำกับภาษี / ใบเสร็จรับเงิน"} </Text>
                      <Text style={styles.headerText}> {"ห้างหุ้นส่วนจำกัดสมศักดิ์คอนกรีต (สำนักงานใหญ่)"} </Text>
                      <Text style={styles.headerText}> {"75/6 ม.2 ต.บางสวรรค์ อ.พระแสง จ.สุราษฎร์ธานี 84210"} </Text>
                      <Text style={styles.headerText}> {"โทรศัพท์ 07-7365401, 08-5784-1509"} </Text>
                      <Text style={styles.headerText}> {"เลขประจำตัวผู้เสียภาษี : 0843561001061"} </Text>
                  </View>
              </View>
          </View>

            <View style={HeaderTable.table}>
              <View style={HeaderTable.tableRow}>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"ผู้ทำรายการ :"} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> {Admin.user_firstname+ " " +Admin.user_lastname} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"วันที่ออกเอกสาร :"} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> 
                    {
                      DateThaiFormat()
                    } 
                  </Text>
                </View>
                
              </View>

              <View style={HeaderTable.tableRow}>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"ผู้ซื้อสินค้า :"} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> {BillData.bill_name} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"วันที่สั่งซื่อ :"} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> {FormatThaiDate(BillData.bill_date)} </Text>
                </View>
              </View>

              <View style={HeaderTable.tableRow}>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"เลขประจำตัวผู้เสียภาษี : "} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> {BillData.bill_buyer_taxid} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"หมายเลขติดต่อ : "} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderInfo}>
                  <Text style={HeaderTable.tableCell}> {BillData.bill_contact} </Text>
                </View>
              </View>

              <View style={HeaderTable.tableRow}>
                <View style={HeaderTable.tableColHeaderDetail}>
                  <Text style={HeaderTable.tableCell}> {"ที่อยู่ส่งสินค้า : "} </Text>
                </View>
                <View style={HeaderTable.tableColHeaderAddress}>
                  <Text style={HeaderTable.tableCell}> {BillData.bill_address} </Text>
                </View>
              </View>

            </View>
            
            <View style={{marginTop: 10}}> </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}> {"ที่"} </Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCellHeader}> {"รายการ"} </Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCellHeader}> {"หน่วยนับ"} </Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCellHeader}> {"จำนวน"} </Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}> {"ราคาต่อหน่วย"} </Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}> {"ราคารวม"} </Text>
                </View>
              </View>


              {AllItemBag.map((item, index) => (
                <View key={item.bag_id} style={styles.tableRow}>
                  {
                      AllItemBag.length == 1 ? (
                          <>
                              <View style={styles.tableColHeaderNumber}>
                                  <Text style={styles.tableCell}> {index + 1} </Text>
                              </View>
                              <View style={styles.tableColHeaderList2}>
                                  <Text style={styles.tableCellList}> {item.bag_item_name} </Text>
                              </View>
                              <View style={styles.tableColHeaderAmountAndUnit}>
                                  <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                              </View>
                              <View style={styles.tableColHeaderAmountAndUnit}>
                                  <Text style={styles.tableCell}> {parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                              <View style={styles.tableCol}>
                                  <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                              <View style={styles.tableCol}>
                                  <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                              </View>
                          </>
                      ) : AllItemBag.length > 1 ? (
                            (index + 1) == 1 ? (
                              <>
                                <View style={List.tableColHeaderNumber_LR_T}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View>
                                <View style={List.tableColHeaderList_LR_T}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR_T}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR_T}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_T}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_T}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            ) : (index + 1) == AllItemBag.length ? (
                              <>
                                <View style={List.tableColHeaderNumber_LR_B}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View>
                                <View style={List.tableColHeaderList_LR_B}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR_B}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR_B}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_B}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR_B}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            ) : (
                              <>
                                <View style={List.tableColHeaderNumber_LR}>
                                    <Text style={styles.tableCell}> {index + 1} </Text>
                                </View>
                                <View style={List.tableColHeaderList_LR}>
                                    <Text style={styles.tableCellList}> {item.bag_item_name} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR}>
                                    <Text style={styles.tableCell}> {item.bag_item_unit} </Text>
                                </View>
                                <View style={List.tableColHeaderAmountAndUnit_LR}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_amount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR}>
                                    <Text style={styles.tableCell}> {parseFloat(item.bag_item_price).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                                <View style={List.tableCol_LR}>
                                    <Text style={styles.tableCell}> {(parseFloat(item.bag_item_price) * parseFloat(item.bag_item_amount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                                </View>
                              </>
                            )
                          
                      ) : null
                  }
                </View>
              ))}

              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}> {" "} </Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}> {"สินค้า " + AllItemBag.length + " รายการ"} </Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"รวมเป็นเงิน"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> { parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 }) } </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}> จำนวน {CountItemAmountAll.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} ชิ้น </Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"ส่วนลด"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {NowPrice.Discount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })} </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"หักส่วนลด"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> 
                  {
                      NowPrice.Discount > 0 ? (
                          <>{parseFloat(NowPrice.IncludedDiscount).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      ) : (
                          <>{parseFloat(NowPrice.TotalPrice).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      )
                  }
                  </Text>
                </View>
              </View>


              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"ภาษีมูลค่าเพิ่ม"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> 
                  {
                      NowPrice.Discount > 0 ? (
                          <>{parseFloat((NowPrice.IncludedDiscount*0.07)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      ) : (
                          <>{parseFloat((NowPrice.TotalPrice*0.07)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                      )
                  }
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderList}>
                  <Text style={styles.tableCell}>
                    {ConvertToThai(ToThaiTotal(NowPrice))}
                  </Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableColHeaderAmountAndUnit}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> {"ราคาสุทธิ"} </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}> 
                    {
                        NowPrice.Discount > 0 ? (
                            <>{parseFloat((NowPrice.IncludedDiscount*0.07)+(NowPrice.IncludedDiscount)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                        ) : (
                            <>{parseFloat((NowPrice.TotalPrice*0.07)+(NowPrice.TotalPrice)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                        )
                    } 
                  </Text>
                </View>
              </View>

            </View>

            <View style={QRCodeStyle.table}>
              <View style={QRCodeStyle.tableRow}>
                <View style={QRCodeStyle.tableColHeaderQRCode}>
                    {/* <PromptPayQRCode mobileNumber={"0857841509"} amount={500} /> */}
                    {/* <Image src={process.env.PUBLIC_URL + "/assets/QRPromptPay/QRCode.png"} style={{width: 70}} /> */}
                    {/* <Image src={'https://promptpay.io/0857841509/100'} style={{width: 70}} /> */}
                    <Image src={QRCode}/>
                    {/* <Image src={"http://promptpay.io/0857841509/"+(parseFloat(NowPrice.TotalPrice)).toString()} style={{width: "40%"}}/> */}
                </View>
                <View style={QRCodeStyle.tableColHeaderQRCodeDetail}>
                  <View style={QRCodeStyle.tableColHeaderQRCodeDetailPadding}>
                    <Text style={QRCodeStyle.headerText}> {"พร้อมเพย์ : 0857841509"} </Text>
                    <Text style={QRCodeStyle.headerText}> {"นายสมศักดิ์ เอี้ยวตระกูล"} </Text>
                    <Text style={QRCodeStyle.headerText}> {"โปรดตรวจสอบชื่อบัญชีและ"} </Text>
                    <Text style={QRCodeStyle.headerText}> {"จำนวนเงินให้ถูกต้องก่อนโอนเงิน"} </Text>
                  </View>
                </View>
                <View style={QRCodeStyle.tableColHeaderQRText}>
                  <Text style={QRCodeStyle.SignText}> {"ผู้รับสินค้า"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"_____________________________"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"(________/__________/________)"} </Text>
                </View>
                <View style={QRCodeStyle.tableColHeaderQRText}>
                  <Text style={QRCodeStyle.SignText}> {"ผู้ตรวจสินค้า"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"_____________________________"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"(________/__________/________)"} </Text>
                </View>
                <View style={QRCodeStyle.tableColHeaderQRText}>
                  <Text style={QRCodeStyle.SignText}> {"ผู้รับเงิน"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"_____________________________"} </Text>
                  <Text style={QRCodeStyle.SignText}> {"(________/__________/________)"} </Text>
                </View>

            </View>

          </View>
          

        </Page>
      </Document>
    );
  }




  export default PrintTaxInvoice;
