import React from 'react'
import NavBar from '../NavBar'

function BillingInvoiceHome() {



  return (
    <>
        <div className="bg-res2" style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.webp)`,
          zIndex: 10,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          backgroundAttachment: 'fixed'
        }}>
            <NavBar/>
            <div className="container-fluid vh-100">
                <div className="row mt-3">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-12 scrollspy-example scrollspy-pos">
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8"></div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BillingInvoiceHome