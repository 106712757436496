export default function DateThaiFormat() {
    // const SetDate = moment().locale('TH').format();
    // console.log(SetDate);
    const date = new Date();
    const DateToThai = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
    return `${DateToThai}`;
  }